export default {
  s3: {
    REGION: 'eu-north-1',
    BUCKET: 'hsr-upload-bucket',
  },
  apiGateway: {
    REGION: 'eu-north-1',
    URL: 'https://f1t4qxmmc3.execute-api.eu-north-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_RHMx37c1U',
    APP_CLIENT_ID: '6oe8o0ffc4lmp4pmv74pnrt5rv',
    IDENTITY_POOL_ID: 'eu-west-1:46e967c0-fa16-4b5b-805f-d8276a42357c',
  },
  SUPERADMIN_GROUP_NAME: 'Superadmin',
  GROUPADMIN_GROUP_NAME: 'Groupadmin',

  MAP_CENTER_POSITION: [59.3124038, 18.0218872],
  DRAWER_WIDTH: 240,
};
