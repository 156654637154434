import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import config from '../config';
import LogoWhite from '../assets/hsrlogowhite.svg';
import TopbarMenu from './TopbarMenu';
import { useAppContext } from '../libs/contextLib';

const drawerWidth = config.DRAWER_WIDTH;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  rightToolbar: {
    marginLeft: 'auto',
    marginRight: -12,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 104,
    padding: 1,
  },
  languageButton: {
    marginRight: theme.spacing(8),
    color: theme.palette.common.white,
  },
  accontIcon: {
    marginRight: theme.spacing(1),
    width: 29,
    height: 29,
  },
}));

export default function Topbar({ userEmail, i18n }) {
  const classes = useStyles();
  const { isAuthenticated, isSidebarOpen, setIsSidebarOpen } = useAppContext();

  const handleDrawerOpen = () => {
    setIsSidebarOpen(true);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isSidebarOpen,
      })}
    >
      <Toolbar>
        {isAuthenticated ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, isSidebarOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <img className={classes.logo} src={LogoWhite} alt="White HSR logo" />
        <section className={classes.rightToolbar}>
          {isAuthenticated ? (
            <>
              <AccountCircleIcon className={classes.accontIcon} />
              <Typography>{userEmail}</Typography>
              <TopbarMenu i18n={i18n} />
            </>
          ) : null}
        </section>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  userEmail: PropTypes.string,
  i18n: PropTypes.shape({
    default: PropTypes.shape({
      language: PropTypes.string.isRequired,
      changeLanguage: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

Topbar.defaultProps = {
  userEmail: '',
};
