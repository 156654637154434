import React, { useState, useEffect } from 'react';

// import { useTranslation } from 'react-i18next';
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
  
import { Bar } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../libs/contextLib';
import { getLocaleCatName } from '../../libs/helperLib';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ByCategoryCases({ data, categories }) {
  const { i18n } = useAppContext();
  const [byCategoryCases, setByCategoryCases] = useState([]);
  const [maxNumForAxis, setMaxNumForAxis] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const byCategoryCasesData = () => {
      const cases = [[], [], [], []];
      data.forEach((item) => {
        const indexOfElement = cases[0].indexOf(item.categoryId);
        if (indexOfElement > -1) {
          cases[1][indexOfElement] += 1;
        } else {
          const category = categories.find((cat) => item.categoryId === cat.id);
          if (category) {
            cases[0].push(category.id);
            cases[1].push(1);
            cases[2].push(category.categoryColorHex);
            cases[3].push(getLocaleCatName(i18n, category));
          }
        }
      });
      setMaxNumForAxis(Math.ceil((Math.max(...cases[1]) + 1) / 10) * 10);

      const barData = {
        labels: cases[3],
        datasets: [
          {
            data: cases[1],
            backgroundColor: cases[2],
            barPercentage: 0.5,
            barThickness: 'flex',
            categoryPercentage: 1.0,
            minBarLength: 5,
          },
        ],
      };
      return barData;
    };

    setByCategoryCases(byCategoryCasesData());
  }, [data, categories, i18n]);
  return (
    <>
      <Typography color="primary">{t('cases_by_category')}</Typography>
      <br />
      <Bar
        data={byCategoryCases}
        width={100}
        height={50}
        options={{
          maintainAspectRatio: true,
          legend: { display: false },
          scales: {
            xAxes: {
              ticks: { autoSkip: false, source: 'data', },
            }
          },
          plugins: {
            legend: {
              display: false,
            }
          }
        }}
        
      />
    </>
  );
}

ByCategoryCases.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
    }),
  ).isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
};
