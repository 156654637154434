import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../libs/contextLib';
import { apiPost } from '../libs/apiLib';
import { getErrorMessage } from '../libs/errorLib';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../constants/constants';

export default function ShareReportDialog(props) {
  const { reportId, handleClose, open } = props;
  const [addUserEmail, setAddUserEmail] = useState('');
  const [errors, setErrors] = useState({ email: false });
  const { t } = useTranslation();

  const validateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
  };
  const validateEmailField = () => {
    if (!validateEmail(addUserEmail) && addUserEmail !== '') {
      setTimeout(() => {
        const newErrorsObj = { ...errors, email: true };
        setErrors(newErrorsObj);
      }, 100);
      return false;
    }
    const newErrorsObj = { ...errors, email: false };
    setErrors(newErrorsObj);
    return true;
  };
  const [emailList, setEmailList] = useState('');
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();

  const handleSubmitClick = () => {
    handleClose();
    const payload = {
      body: { emails: emailList },
    };
    apiPost(`/reports/${reportId}/share`, payload)
      .then(() => {
        setEmailList(payload);
        setSnackbarType(SNACKBAR_TYPE_SUCCESS);
        setSnackbarMessage(t('report_share_success'));
        setIsSnackbarOpen(true);
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
  };
  const evaluateEmailValue = (emailAddress, focus) => {
    if (validateEmail(emailAddress)) {
      const newErrorsObj = { ...errors, email: false };
      setEmailList(emailAddress);
      setErrors(newErrorsObj);
      setAddUserEmail(emailAddress);
    } else {
      const newErrorsObj = { ...errors, email: true };
      setEmailList('');
      if (!focus) setErrors(newErrorsObj);
      setAddUserEmail('');
    }
  };
  const handleAddUserEmailOnChange = (event) => {
    const emailAddressList = event.target.value;
    evaluateEmailValue(emailAddressList, false);
  };
  const getEmailValue = (event) => {
    const emailAddressList = event.target.value;
    evaluateEmailValue(emailAddressList, true);
  };
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose} aria-labelledby="add-comment">
      <DialogTitle>{t('shareReport_title')}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={handleAddUserEmailOnChange}
          autoFocus
          margin="dense"
          id="name"
          label={t('email_address')}
          type="email"
          fullWidth
          placeholder=" "
          onBlur={validateEmailField}
          onFocus={getEmailValue}
          error={errors.email && addUserEmail === ''}
          helperText={errors.email && addUserEmail === '' ? 'Enter valid email' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button className="reportDetails__popover__triggerButton" color="primary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button
          className="reportDetails__popover__button"
          disabled={addUserEmail === ''}
          variant="contained"
          color="primary"
          onClick={handleSubmitClick}
        >
          {t('reportDetails__shareReport__button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ShareReportDialog.propTypes = {
  reportId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
