import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Button } from '@material-ui/core';
import { format } from 'date-fns';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import PatchedPagination from '../../components/PatchedPagination';
import ColorPicker from '../../components/ColorPicker';
import AddNewCategoryDialog from '../../components/AddNewCategoryDialog';
import SubCategoryEdit from '../../components/SubCategoryEdit';
import { useAppContext } from '../../libs/contextLib';
import { getErrorMessage } from '../../libs/errorLib';
import { refreshData, apiPut } from '../../libs/apiLib';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 5,
    paddingRight: 5,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 300,
  },
  addNewCategoryButton: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
  },
}));

export default function Categories() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [subCategoryEditData, setSubCategoryEditData] = useState(null);
  const { isSuperadmin, isAuthenticated, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const refreshDataWithParams = async () => refreshData('/categories', isAuthenticated, setData, setIsLoading);

  const [addCategoryOpen, setAddCategoryOpen] = useState(false);

  const handleClickOpen = () => {
    setAddCategoryOpen(true);
  };

  const handleClose = () => {
    setAddCategoryOpen(false);
  };

  const handleSubCategoryEditClose = () => {
    setSubCategoryEditData(null);
  };

  useEffect(() => {
    refreshDataWithParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isSuperadmin) {
    return null;
  }
  return (
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CustomMaterialTable
              columns={[
                { title: t('category_name_column_en'), field: 'name.EN' },
                { title: t('category_name_column_se'), field: 'name.SE' },
                { title: t('category_order'), field: 'order', type: 'numeric' },
                // { title: t('category_id_column'), field: 'id', filtering: false, editable: 'never' },
                {
                  title: 'Color',
                  field: 'categoryColorHex',
                  render: (rowData) => (
                    <div
                      style={{
                        backgroundColor: rowData.categoryColorHex,
                        width: '65px',
                        height: '20px',
                        borderRadius: 8,
                      }}
                    />
                  ),
                  editComponent: (rowData) => <ColorPicker categoryColorHex={rowData.value} onChange={rowData.onChange} />,
                },
                {
                  title: 'Subcategories',
                  field: 'subCategories',
                  render: (rowData) => {
                    const subCats = rowData.subCategories.map((subCatId) => data.find((cat) => cat.id === subCatId));
                    if (subCats.length) {
                      return (
                        <div>
                          {subCats.map((subCat) => (
                            <div key={subCat.id}>
                              <span>
                                {subCat.name.EN} / {subCat.name.SE}
                              </span>
                              <br />
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  },
                  editComponent: (rowData) => (
                    <Button
                      disabled={rowData.rowData.parentId.length > 0}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSubCategoryEditData(rowData.rowData);
                      }}
                    >
                      Edit subcategories
                    </Button>
                  ),
                },
              ]}
              data={data}
              title={t('categories_table_title')}
              options={{
                search: true,
                pageSize: 30,
                pageSizeOptions: [],
                exportButton: true,
                exportAllData: true,
                exportFileName: `Categories export ${format(new Date(), 'dd.MM.yyyy')}`,
              }}
              components={{
                Pagination: PatchedPagination,
              }}
              editable={{
                isDeleteHidden: () => true,
                isEditHidden: () => !isSuperadmin,
                onRowUpdate: (updatedData) => {
                  const payload = {
                    body: {
                      id: updatedData.id,
                      name: { EN: updatedData.name.EN, SE: updatedData.name.SE },
                      order: updatedData.order,
                      categoryColorHex: updatedData.categoryColorHex,
                    },
                  };
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      apiPut('/categories', payload)
                        .then(() => refreshDataWithParams())
                        .then(() => {
                          resolve();
                          setSnackbarType(SNACKBAR_TYPE_SUCCESS);
                          setSnackbarMessage(t('category_update_success'));
                          setIsSnackbarOpen(true);
                        })
                        .catch((e) => {
                          setSnackbarType(SNACKBAR_TYPE_ERROR);
                          setSnackbarMessage(getErrorMessage(e));
                          setIsSnackbarOpen(true);
                        });
                    });
                  });
                },
                // onRowDelete: (deleteData) => {
                //   return new Promise((resolve) => {
                //     setTimeout(() => {
                //       apiDelete(`/categories/${deleteData.id}`)
                //         .then(() => refreshDataWithParams())
                //         .then(() => {
                //           resolve();
                //           setSnackbarType(SNACKBAR_TYPE_SUCCESS);
                //           setSnackbarMessage(t('category_delete_success'));
                //           setIsSnackbarOpen(true);
                //         })
                //         .catch((e) => {
                //           setSnackbarType(SNACKBAR_TYPE_ERROR);
                //           setSnackbarMessage(getErrorMessage(e));
                //           setIsSnackbarOpen(true);
                //         });
                //     });
                //   });
                // },
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
            />
          )}
          <br />
          {!isLoading && (
            <Fab onClick={handleClickOpen} variant="extended" className={classes.addNewCategoryButton} color="primary" aria-label="addNewCategory">
              <AddIcon />
              {t('add_new_category')}
            </Fab>
          )}
        </Container>
        <AddNewCategoryDialog open={addCategoryOpen} handleClose={handleClose} refreshData={refreshDataWithParams} classes={classes} />
        <SubCategoryEdit
          targetCategory={subCategoryEditData}
          handleClose={handleSubCategoryEditClose}
          refreshData={refreshDataWithParams}
          data={data}
        />
      </main>
  );
}
