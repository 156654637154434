import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../libs/contextLib';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    minHeight: 0,
    minWidth: 0,
    color: theme.palette.common.white,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function TopbarMenu({ i18n }) {
  const getLanguage = () => i18n.default.language;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [nestedOpen, setnestedOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(getLanguage());
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();
  const { setIsAuthenticated, setIsSuperadmin } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleLanguage = (lang) => {
    if (lang === 'se') {
      setSelectedLanguage('se');
      i18n.default.changeLanguage('se');
    } else {
      setSelectedLanguage('en');
      i18n.default.changeLanguage('en');
    }

    // Reloading page on front page to change the language of chart columns.
    if (location.pathname === '/') {
      navigate(0);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setnestedOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleNestedClick = () => {
    setnestedOpen(!nestedOpen);
  };

  const goToChangePassword = () => {
    setOpen(false);
    navigate('/changePassword');
  };

  async function handleLogout() {
    await Auth.signOut();
    setIsAuthenticated(false);
    setIsSuperadmin(false);
    navigate('/login');
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classes.menuButton}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          p={1}
        >
          <KeyboardArrowDownIcon />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              in={TransitionProps.in}
              onEnter={TransitionProps.onEnter}
              onExited={TransitionProps.onExited}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <List autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <ListItem button onClick={handleNestedClick}>
                      <ListItemText primary={t('language')} />
                      {nestedOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          selected={selectedLanguage === 'se'}
                          onClick={() => toggleLanguage('se')}
                        >
                          <ListItemText primary="SE" />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.nested}
                          selected={selectedLanguage === 'en'}
                          onClick={() => toggleLanguage('en')}
                        >
                          <ListItemText primary="EN" />
                        </ListItem>
                      </List>
                    </Collapse>
                    <ListItem button onClick={goToChangePassword}>
                      <ListItemText primary={t('change_password')} />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                      <ListItemText primary={t('logout')} />
                    </ListItem>
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

TopbarMenu.propTypes = {
  i18n: PropTypes.shape({
    default: PropTypes.shape({
      language: PropTypes.string.isRequired,
      changeLanguage: PropTypes.func.isRequired,
    }),
  }).isRequired,
};
