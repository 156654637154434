import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { CircularProgress, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import PatchedPagination from '../../components/PatchedPagination';
import AddNewUserDialog from '../../components/AddNewUserDialog';
import RoleChangeRadio from '../../components/RoleChangeRadio';
import { refreshData, apiPut, apiDelete, apiGet } from '../../libs/apiLib';
import { getErrorMessage } from '../../libs/errorLib';
import { useAppContext } from '../../libs/contextLib';
import {
  SNACKBAR_TYPE_ERROR,
  SNACKBAR_TYPE_SUCCESS,
  USER_ROLE_USER,
  USER_ROLE_SUPERADMIN,
  USER_ROLE_GROUPADMIN,
} from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 5,
    paddingRight: 5,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 300,
  },
  addNewUserButton: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
  },
  superAdminButton: {
    marginTop: theme.spacing(1),
    marginLeft: 2,
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Users() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { isAuthenticated, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, isSuperadmin } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [addUserOpen, setAddUsertOpen] = useState(false);
  const [superadmins, setSuperadmins] = useState([]);
  const [groupadmins, setGroupadmins] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const { currentUserMunicipality } = useAppContext();

  const handleClickOpen = () => {
    setAddUsertOpen(true);
  };

  const handleClose = () => {
    setAddUsertOpen(false);
  };

  const refreshDataWithParams = async () => refreshData('/users', isAuthenticated, setData, setIsLoading);
  const getMunicipalities = async () => refreshData('/municipalities', isAuthenticated, setMunicipalities, setIsLoading);

  const setAdminList = async (group) => {
    apiGet(`/users/role/${group}`)
      .then((result) => {
        if (group === 'Superadmin') {
          setSuperadmins(result);
        } else {
          setGroupadmins(result);
        }
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
  };

  const newUserAdded = () => {
    setAdminList('Superadmin');
    setAdminList('Groupadmin');
  };

  useEffect(() => {
    refreshDataWithParams();
    getMunicipalities();
    setAdminList('Superadmin');
    setAdminList('Groupadmin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const formatRowData = (dataToBeFormatted) => {
    const formattedData = dataToBeFormatted.map((item) => {
      const municipality = item['custom:Municipality'];
      let role = !municipality ? USER_ROLE_USER : "";
      if (role === "" && municipality) { role = USER_ROLE_GROUPADMIN }
      if (superadmins.includes(item.sub)) {
        role = USER_ROLE_SUPERADMIN;
      } else if (groupadmins.includes(item.sub)) {
        role = USER_ROLE_GROUPADMIN;
      }
      const retItem = { ...item, role, 'custom:Municipality': municipality };
      return retItem;
    });
    const groupAdminFormatedData = formattedData.filter((e) => ( e['role'] !== 'USER' && e['custom:Municipality'] === currentUserMunicipality ));
    const rowData = !isSuperadmin ? groupAdminFormatedData : formattedData;
    return rowData;
  };

  return (
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CustomMaterialTable id="users-table"
              columns={[
                { title: t('user_column_email'), field: 'email', editable: 'never' },
                { title: t('category_name_column_en'), field: 'name', editable: 'never' },
                { title: t('phone_number'), field: 'phone_number', editable: 'never' },
                {
                  title: t('user_column_municipality'),
                  field: 'custom:Municipality',
                  render: (rowData) => ( rowData.role === USER_ROLE_USER ? '' : rowData['custom:Municipality'] ),
                  editComponent: (rowData) => (
                    <MunicipalitySelect municipality={rowData.value} onChange={rowData.onChange} municipalities={municipalities} role={rowData.rowData.role} />
                  ),
                },
                {
                  title: t('user_column_role'),
                  field: 'role',
                  editComponent: (rowData) => <RoleChangeRadio role={rowData.value} onChange={rowData.onChange} />,
                },
              ]}
              data={formatRowData(data)}
              title={t('users_table_title')}
              options={{
                search: true,
                pageSize: 10,
                pageSizeOptions: [],
                exportButton: true,
                exportAllData: true,
                exportFileName: `Users export ${format(new Date(), 'dd.MM.yyyy')}`,
              }}
              components={{
                Pagination: PatchedPagination,
              }}
              editable={{
                isDeleteHidden: rowData => rowData.role === USER_ROLE_USER || !isSuperadmin,
                isEditHidden: rowData => rowData.role === USER_ROLE_USER || !isSuperadmin,
                onRowUpdate: (updatedData, rowData) => {
                  const updateRequests = [];
                  const rolePayload = {
                    body: { group: updatedData.role, municipality: updatedData.municipality ? updatedData.municipality : municipalities[0].name },
                  };
                  updateRequests.push(apiPut(`/users/${updatedData.sub}`, rolePayload));
                  const municipalityPayload = {
                    body: { municipality: updatedData['custom:Municipality'] ? updatedData['custom:Municipality'] : updatedData['custom:Municipality'] = municipalities[0].name }
                  };
                  updateRequests.push(apiPut(`/users/municipality/${updatedData.sub}`, municipalityPayload));
                  return Promise.all(updateRequests)
                    .then(() => {
                      setSnackbarType(SNACKBAR_TYPE_SUCCESS);
                      setSnackbarMessage(t('user_update_success'));
                      setIsSnackbarOpen(true);
                      refreshDataWithParams();
                      setAdminList('Superadmin');
                      setAdminList('Groupadmin');
                    })
                    .catch((e) => {
                      setSnackbarType(SNACKBAR_TYPE_ERROR);
                      setSnackbarMessage(getErrorMessage(e));;
                      setIsSnackbarOpen(true);
                    });
                },
                onRowDelete: (deleteData) => (
                  new Promise((resolve) => {
                    apiDelete(`/users/${deleteData.sub}`)
                      .then(() => {
                        setSnackbarType(SNACKBAR_TYPE_SUCCESS);
                        setSnackbarMessage(t('user_delete_success'));
                        setIsSnackbarOpen(true);
                        refreshDataWithParams();
                      })
                      .then(resolve())
                      .catch((e) => {
                        setSnackbarType(SNACKBAR_TYPE_ERROR);
                        setSnackbarMessage(getErrorMessage(e));
                        setIsSnackbarOpen(true);
                      });
                  })
                ),
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
            />
          )}
          <br />
          {!isLoading && isSuperadmin && (
            <Fab variant="extended" onClick={handleClickOpen} className={classes.addNewUserButton} color="primary" aria-label="addNewUser">
              <AddIcon />
              {t('add_new_user')}
            </Fab>
          )}
        </Container>
        <AddNewUserDialog
          open={addUserOpen}
          handleClose={handleClose}
          refreshData={refreshDataWithParams}
          newUserAdded={newUserAdded}
          classes={classes}
          municipalities={municipalities}
        />
      </main>
  );
}

function MunicipalitySelect({ municipality, municipalities, onChange, role }) {
  const options = municipalities.map((option) => option.name);
  if (!municipality && role !== USER_ROLE_SUPERADMIN) { municipality = options[0]; }
  const [selectedMunicipality, setSelectedMunicipality] = useState(municipality);
  const handleOnChange = (value) => {
    if (value) {
      setSelectedMunicipality(value);
      onChange(value);
    } else {
      setSelectedMunicipality(municipality);
      onChange(value);
    }
  };
  if (role === USER_ROLE_SUPERADMIN) {
    return '';
  }
  return (
    <Autocomplete
      includeInputInList
      disableClearable
      options={options}
      value={selectedMunicipality || options[0]}
      id="municipality"
      // TODO: fix this eslint error later
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} label="Municipality" />}
      onChange={(event, newValue) => {
        if (newValue) {
          handleOnChange(newValue);
        }
      }}
    />
  );
}

MunicipalitySelect.propTypes = {
  municipality: PropTypes.string,
  municipalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
