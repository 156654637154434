import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { Grid, Paper, CircularProgress, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isAfter, isBefore, isSameDay, parseISO, endOfYear, startOfMonth, endOfMonth, subMonths, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import InboxIcon from '@material-ui/icons/Inbox';
import UpdateIcon from '@material-ui/icons/Update';
import DoneIcon from '@material-ui/icons/Done';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import DashboardFilter from '../../components/DashboardFilter';
import PatchedPagination from '../../components/PatchedPagination';
import { useAppContext } from '../../libs/contextLib';
import { refreshData } from '../../libs/apiLib';
import { getErrorMessage } from '../../libs/errorLib';
import { formatDateToStr, getLocaleCatName } from '../../libs/helperLib';
import '../../styles/ReportDetails.css';
import {
  SNACKBAR_TYPE_ERROR,
  REPORT_STATUS_IN_PROGRESS,
  REPORT_STATUS_OPEN,
  DASHBOARD_DATA_INTERVAL_MONTH,
  DASHBOARD_DATA_INTERVAL_6MONTHS,
  DASHBOARD_DATA_INTERVAL_YEAR,
} from '../../constants/constants';
import ByCategoryCases from '../../components/charts/ByCategoryCases';
import ByMunicipalitiesCases from '../../components/charts/ByMunicipalitiesCases';
import NumberOfCases from '../../components/charts/NumberOfCases';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 5,
    paddingRight: 5,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 300,
  },
  filterButton: {
    float: 'right',
    paddingLeft: theme.spacing(1),
    display: 'inline-block',
  },
  chartTitle: {
    display: 'inline-block',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end',
  },
}));

export default function Dashboard() {

  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const classes = useStyles();
  const { isAuthenticated, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, i18n } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [dateRange, setDateRange] = useState('CUSTOM');
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(year, month - 6, day));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [municipalityFilters, setMunicipalityFilters] = useState([]);
  const navigate = useNavigate();
  const getItemStatus = (itemStatus) => {
    const status = itemStatus.toLowerCase();
    switch (status) {
      case REPORT_STATUS_IN_PROGRESS:
        return (
          <div className="status progress">
            <div className="content">
              <UpdateIcon />
              <span>{t('in_progress')}</span>
            </div>
          </div>
        );
      case REPORT_STATUS_OPEN:
        return (
          <div className="status received">
            <div className="content">
              <InboxIcon />
              <span>{t('received')}</span>
            </div>
          </div>
        );
      default:
        return (
          <div className="status resolved">
            <div className="content">
              <DoneIcon />
              <span>{t('resolved')}</span>
            </div>
          </div>
        );
    }
  };

  /*

                { title: t('report_column_image'), field: "thumbnail", render: rowData => <img src={rowData.thumbnailUrl} style={{ width: 70 }} />, filtering: false },
    */

  const filterData = (range) => {
    const currentDate = new Date();
    let startDate = selectedStartDate;
    let endDate = selectedEndDate;
    if (range === DASHBOARD_DATA_INTERVAL_MONTH) {
      startOfMonth(subDays(currentDate, 31));
      endDate = endOfMonth(currentDate);
    } else if (range === DASHBOARD_DATA_INTERVAL_6MONTHS) {
      startDate = startOfMonth(subMonths(currentDate, 6));
      endDate = endOfMonth(currentDate);
    } else if (range === DASHBOARD_DATA_INTERVAL_YEAR) {
      startDate = startOfMonth(subMonths(currentDate, 12));
      endDate = endOfYear(currentDate);
    }
    try {
      setFilteredData(
        data.filter((report) => {
          if (categoryFilters.length && !categoryFilters.includes(report.categoryId)) {
            return false;
          }
          if (municipalityFilters.length && !municipalityFilters.some((m) => m.name === report.municipality)) {
            return false;
          }
          const reportDate = parseISO(report.createDate);
          if (startDate && endDate) {
            return (
              (isAfter(reportDate, startDate) || isSameDay(reportDate, startDate)) &&
              (isBefore(reportDate, endDate) || isSameDay(reportDate, endDate))
            );
          }
          if (startDate) {
            return isAfter(reportDate, startDate) || isSameDay(reportDate, startDate);
          }
          if (endDate) {
            return isBefore(reportDate, endDate) || isSameDay(reportDate, endDate);
          }
          return true;
        }),
      );
    } catch (e) {
      setSnackbarType(SNACKBAR_TYPE_ERROR);
      setSnackbarMessage(getErrorMessage(e));
      setIsSnackbarOpen(true);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    filterData(dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedStartDate, selectedEndDate, categoryFilters, municipalityFilters, dateRange]);

  useEffect(() => {
    const refreshDataWithParams = async () => refreshData('/reports/allreports/true', isAuthenticated, setData, setIsLoading);
    const refreshCategoriesDataWithParams = async () => refreshData('/categories', isAuthenticated, setCategories, setIsLoading);
    const refrefreshMunicipalitiesDataWithParams = async () =>
      refreshData('/municipalities', isAuthenticated, setMunicipalities, setIsLoading);
    refreshDataWithParams();
    refreshCategoriesDataWithParams();
    refrefreshMunicipalitiesDataWithParams();
  }, [isAuthenticated]);

  const goToDetails = (rowData) => {
    navigate(`/reports/${rowData.id}`);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
      <main className={classes.content}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={2} m={0}>
            <Grid item xs={12} md={12}>
              <DashboardFilter
                filterData={filterData}
                categories={categories}
                municipalities={municipalities}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                categoryFilters={categoryFilters}
                municipalityFilters={municipalityFilters}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                setCategoryFilters={setCategoryFilters}
                setMunicipalityFilters={setMunicipalityFilters}
                setDateRange={setDateRange}
              />
            </Grid>
            {/* Total Cases doughnut */}
            <Grid item xs={12} md={4}>
              <Paper className={fixedHeightPaper}>
                <Box className={classes.filterBox}>
                  <Typography color="primary" className={classes.chartTitle}>
                    {t('number_of_cases')}
                  </Typography>
                </Box>
              <br />
                <NumberOfCases data={filteredData} range={dateRange} customStart={selectedStartDate} customEnd={selectedEndDate} />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4}>
              <Paper className={fixedHeightPaper}> 
                <ByMunicipalitiesCases chartData={filteredData} municipalities={municipalities} isLoading={isLoading} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={fixedHeightPaper}>
                <ByCategoryCases data={filteredData} categories={categories} isLoading={isLoading} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="view-container">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <CustomMaterialTable
                    columns={[
                      { title: t('email_column'), field: 'userEmail', filtering: false },
                      {
                        title: t('date_column'),
                        field: 'createDate',
                        type: 'date',
                        filtering: false,
                        defaultSort: 'desc',
                        render: (rowData) => formatDateToStr(new Date(rowData.createDate)),
                      },
                      {
                        title: t('report_column_status'),
                        field: 'status',
                        filtering: false,
                        render: (rowData) => getItemStatus(rowData.status),
                      },
                      { title: t('report_column_municipality'), field: 'municipality', filtering: false },
                      {
                        title: t('category'),
                        field: 'categoryName',
                        filtering: false,
                        render: (rowData) =>
                          categories.length
                            ? getLocaleCatName(
                              i18n,
                              categories.find((cat) => cat.id === rowData.categoryId),
                            )
                            : undefined,
                      },
                    ]}
                    data={filteredData}
                    title={t('latest_user_reported_cases')}
                    options={{
                      search: true,
                      filtering: false,
                      pageSize: 10,
                      pageSizeOptions: [],
                      toolbar: false,
                      draggable: false,
                      padding: 'dense',
                    }}
                    components={{
                      Pagination: PatchedPagination,
                    }}
                    onRowClick={(event, rowData) => goToDetails(rowData)}
                  />
                  )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
  );
}
