import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { SliderPicker } from 'react-color';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../constants/constants';
import { useAppContext } from '../libs/contextLib';
import { getErrorMessage } from '../libs/errorLib';
import { apiPost, apiGet } from '../libs/apiLib';

const useStyles = makeStyles((theme) => ({
  colortypo: {
    marginBottom: theme.spacing(2),
  },
  nameTextField: {
    marginBottom: theme.spacing(4),
  },
}));

export default function AddNewCategoryDialog({ refreshData, handleClose, open }) {
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const [isUnique, setIsUnique] = useState(true);
  const [addCategoryNameEN, setAddCategoryNameEN] = useState('');
  const [addCategoryNameSE, setAddCategoryNameSE] = useState('');
  const [addCategoryOrder, setAddCategoryOrder] = useState('');
  const [addCategoryCategoryColorHex, setAddCategoryCategoryColorHex] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();

  const AddNewCategoryClick = () => {
    const payload = {
      body: {
        name: { EN: addCategoryNameEN, SE: addCategoryNameSE },
        order: addCategoryOrder,
        categoryColorHex: addCategoryCategoryColorHex,
      },
    };

    apiPost('/categories', payload)
      .then((response) => {
        refreshData(response);
        setSnackbarType(SNACKBAR_TYPE_SUCCESS);
        setSnackbarMessage(t('category_added_success'));
        setIsSnackbarOpen(true);
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
    handleClose();
  };

  const handleAddCategoryNameENOnChange = (event) => {
    setAddCategoryNameEN(event.target.value);
  };

  const handleAddCategoryNameSEOnChange = (event) => {
    setAddCategoryNameSE(event.target.value);
  };

  const handleAddCategoryOrderOnChange = (event) => {

    const enteredVal = event.target.value;

    apiGet('/categories')      
      .then((result) => {
        const value = result.find(({ order }) => order === enteredVal);

        setIsUnique(false);

        if (value && enteredVal !== null) {
          setIsUnique(true);
        }

        else {
          setIsUnique(false);
        }
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
    
      setAddCategoryOrder(event.target.value);
  };

  const handleAddCategoryCategoryColorHexChange = (color) => {
    setAddCategoryCategoryColorHex(color.hex);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="add-new-category">
      <DialogTitle id="add-new-category">{t('add_category')}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={handleAddCategoryNameENOnChange}
          autoFocus
          margin="dense"
          id="name"
          label={t('category_name_en')}
          type="text"
          fullWidth
          placeholder=" "
          className={classes.nameTextField}
        />
        <TextField
          onChange={handleAddCategoryNameSEOnChange}
          margin="dense"
          id="name"
          label={t('category_name_se')}
          type="text"
          fullWidth
          placeholder=" "
          className={classes.nameTextField}
        />
        <TextField
          onChange={handleAddCategoryOrderOnChange}
          margin="dense"
          id="name"
          label={t('category_order')}
          type="text"
          fullWidth
          placeholder=" "
          className={classes.nameTextField}
        />
        <Typography className={classes.colortypo} color="primary" variant="body2" display="block" gutterBottom>
          {t('choose_color')}
        </Typography>
        <SliderPicker color={addCategoryCategoryColorHex} onChange={handleAddCategoryCategoryColorHexChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={AddNewCategoryClick} color="primary" variant="contained" disabled={isUnique === true}>
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddNewCategoryDialog.propTypes = {
  refreshData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
