import React from 'react';
import PropTypes from 'prop-types';

export default function GoogleMaps({ width, height, latitude, longitude }) {
  const url = `https://www.google.com/maps/embed/v1/place?q=${latitude},${longitude}&key=AIzaSyBy9rawOD9-TEVzFRPtxxN6KLweWt8tx00&center=${latitude},${longitude}`;
  if (!latitude || !longitude) {
    return null;
  }
  return <iframe title="map" width={width} height={height} frameBorder="0" src={url} allowFullScreen />;
}

GoogleMaps.defaultProps = {
  latitude: undefined,
  longitude: undefined,
};

GoogleMaps.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
