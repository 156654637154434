import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getErrorMessage } from '../../libs/errorLib';
import Logo from '../../assets/hsrlogo.svg';
import '../../styles/Forms.css';
import { useAppContext } from '../../libs/contextLib';
import { SNACKBAR_TYPE_SUCCESS, SNACKBAR_TYPE_ERROR } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    marginBottom: theme.spacing(8),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const navigate = useNavigate();

  function validateForm() {
    return currentPassword.length > 0 && newPassword === passwordConfirmation;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.currentAuthenticatedUser()
        .then((user) => ( Auth.changePassword(user, currentPassword, newPassword) ))
        .then(() => {
          setSnackbarType(SNACKBAR_TYPE_SUCCESS);
          setSnackbarMessage(t('password_change_success'));
          setIsSnackbarOpen(true);
          navigate('/');
        })
        .catch((err) => {
          setSnackbarType(SNACKBAR_TYPE_ERROR);
          setSnackbarMessage(getErrorMessage(err));
          setIsSnackbarOpen(true);
          setIsLoading(false);
        });
    } catch (e) {
      setSnackbarType(SNACKBAR_TYPE_ERROR);
      setSnackbarMessage(getErrorMessage(e));
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }
  }

  return (
    <div className="unauthenticated__form__container">
      <img className={classes.logo} src={Logo} alt="HSR Logo" />
      <form onSubmit={handleSubmit}>
        <TextField
          type="password"
          margin="normal"
          required
          fullWidth
          id="currentPassword"
          label="Current Password"
          name="currentPassword"
          autoFocus
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          type="password"
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="New Password"
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          type="password"
          margin="normal"
          required
          fullWidth
          id="passwordConfirmation"
          label="Password Confirmation"
          name="passwordConfirmation"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={!validateForm() || isLoading}>
          {isLoading ? <CircularProgress size="24.5px" style={{ marginRight: 5 }} /> : null}
          {t('reset_password')}
        </Button>
      </form>
    </div>
  );
}
