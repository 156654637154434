import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/Forms.css';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/hsrlogo.svg';
import { useAppContext } from '../../libs/contextLib';
import { getErrorMessage } from '../../libs/errorLib';
import { getGroups, isAdmin, isSuperadminCheck } from '../../libs/loginLib';
import { SNACKBAR_TYPE_ERROR } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    marginBottom: theme.spacing(8),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  forgotPassword: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const {
    setIsAuthenticated,
    setIsSuperadmin,
    setIsSidebarOpen,
    setCognitoUser,
    setIsSnackbarOpen,
    setSnackbarMessage,
    setSnackbarType,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const authorizeIfAdmin = (user) => {
    const groups = getGroups(user);
    if (groups === undefined) {
      throw new Error(t('user_not_admin_error'));
    }
    if (isAdmin(groups)) {
      if (isSuperadminCheck(groups)) {
        setIsSuperadmin(true);
      }
      setIsAuthenticated(true);
      setIsSidebarOpen(true);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(email, password).then((user) => {
        setCognitoUser(user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate('/forcedPasswordChange');
        } else {
          authorizeIfAdmin(user);
        }
      });
    } catch (e) {
      setSnackbarType(SNACKBAR_TYPE_ERROR);
      setSnackbarMessage(getErrorMessage(e));
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }
  }

  const goToForgottenPassword = () => {
    navigate('/resetPasswordRequest');
  };

  return (
    <div className="unauthenticated__form__container">
      <img className={classes.logo} src={Logo} alt="HSR Logo" />
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={!validateForm() || isLoading}>
          {isLoading ? <CircularProgress size="24.5px" style={{ marginRight: 5 }} /> : null}
          {t('login')}
        </Button>

        <Typography color="primary" variant="subtitle2" className={classes.forgotPassword} onClick={() => goToForgottenPassword()}>
          {t('forgot_my_password')}
        </Typography>
      </form>
    </div>
  );
}
