import React, { useState, useEffect } from 'react';

// import { useTranslation } from 'react-i18next';
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
  
import { Doughnut } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function ByMunicipalitiesCases({ chartData, municipalities }) {
  const [byMunicipalityCases, setMunicipalityCases] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const byMunicipalityCasesData = () => {
      const cases = [[], [], []];
      chartData.forEach((item) => {
        const indexOfElement = cases[0].indexOf(item.municipality);
        if (indexOfElement > -1) {
          cases[1][indexOfElement] += 1;
        } else {
          const municipality = municipalities.find((muni) => item.municipality === muni.name);
          if (municipality) {
            cases[0].push(municipality.name);
            cases[1].push(1);
            cases[2].push(municipality.color);
          }
        }
      });

      const doughnutData = {
        labels: cases[0],
        datasets: [
          {
            backgroundColor: cases[2],
            data: cases[1],
          },
        ],
      };
      return doughnutData;
    };

    setMunicipalityCases(byMunicipalityCasesData());
  }, [chartData, municipalities]);

  return (
    <>
      <Typography color="primary">{t('cases_by_municipality')}</Typography>
      <br />
      <Doughnut
        data={byMunicipalityCases}
        options={{
          aspectRatio: 2,
          maintainAspectRatio: true,
          legend: {
            position: 'left',
          },
        }}
      />
    </>
  );
}

ByMunicipalitiesCases.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  municipalities: PropTypes.instanceOf(Array).isRequired,
};
