import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import '../../styles/Forms.css';
import Logo from '../../assets/hsrlogo.svg';
import { useAppContext } from '../../libs/contextLib';
import { getErrorMessage } from '../../libs/errorLib';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    marginBottom: theme.spacing(8),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();

  function validateForm() {
    return email.length > 0 && code.length > 0 && password === passwordConfirmation;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
        .then(() => navigate('/login'))
        .catch((err) => {
          setSnackbarType('error');
          setSnackbarMessage(getErrorMessage(err));
          setIsSnackbarOpen(true);
          setIsLoading(false);
        });
    } catch (e) {
      setSnackbarType('error');
      setSnackbarMessage(getErrorMessage(e));
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }
  }

  return (
    <div className="unauthenticated__form__container">
      <img className={classes.logo} src={Logo} alt="HSR Logo" />
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          name="code"
          autoFocus
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <TextField
          type="email"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('email_address')}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          margin="normal"
          required
          fullWidth
          id="password"
          label={t('password')}
          name="password"
          autoFocus
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          type="password"
          margin="normal"
          required
          fullWidth
          id="passwordConfirmation"
          label={t('password_confirmation')}
          name="passwordConfirmation"
          autoFocus
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={!validateForm() || isLoading}>
          {isLoading ? <CircularProgress size="24.5px" style={{ marginRight: 5 }} /> : null}
          {t('reset_password')}
        </Button>
      </form>
    </div>
  );
}
