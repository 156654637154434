import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './views/Login/Login';
import ResetPasswordRequest from './views/ResetPasswordRequest/ResetPasswordRequest';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ForcedPasswordChange from './views/ForcedPasswordChange/ForcedPasswordChange';
import Dashboard from './views/Dashboard/Dashboard';
import Users from './views/Users/Users';
import Reports from './views/Reports/Reports';
import ReportDetails from './views/Reports/ReportDetails';
import ChangePassword from './views/ChangePassword/ChangePassword';
import Categories from './views/Categories/Categories';
import SharedReport from './views/Reports/SharedReport';
import InfotextEdit from './views/Infotext/InfotextEdit';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default function PageRoutes() {
  return (

    <Routes>
      <Route path="/login" element={
        <UnauthenticatedRoute>
          <Login />
        </UnauthenticatedRoute>} />
        <Route path="/resetPasswordRequest" element={
          <UnauthenticatedRoute>
            <ResetPasswordRequest />
        </UnauthenticatedRoute>} />
      
        <Route path="/forcedPasswordChange" element={
          <UnauthenticatedRoute>
            <ForcedPasswordChange />
        </UnauthenticatedRoute>} />
      
        <Route path="/resetPassword" element={
          <UnauthenticatedRoute>
            <ResetPassword />
        </UnauthenticatedRoute>} />
      
        <Route path="/reports/:id/share/:passCode" element={
          <UnauthenticatedRoute>
            <SharedReport />
        </UnauthenticatedRoute>} />
      
        <Route path="/users" element={
        <AuthenticatedRoute>
          <Users />
        </AuthenticatedRoute>} />
        <Route path="/reports" element={
        <AuthenticatedRoute>
          <Reports />
        </AuthenticatedRoute>} />
        <Route path="/reports/:id" element={
        <AuthenticatedRoute>
          <ReportDetails />
        </AuthenticatedRoute>} />
        <Route path="/categories" element={
        <AuthenticatedRoute>
          <Categories />
        </AuthenticatedRoute>} />
        <Route path="/infotext" element={
        <AuthenticatedRoute>
          <InfotextEdit />
        </AuthenticatedRoute>} />
        <Route path="/changePassword" element={
        <AuthenticatedRoute>
          <ChangePassword />
        </AuthenticatedRoute>} />
      <Route path="" element={
        <AuthenticatedRoute>
          <Dashboard />
        </AuthenticatedRoute>} />
    </Routes>



    /*
    <Routes >
      <UnauthenticatedRoute path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/resetPasswordRequest">
        <ResetPasswordRequest />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/resetPassword">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forcedPasswordChange">
        <ForcedPasswordChange />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/reports/:id/share/:passCode">
        <SharedReport />
      </UnauthenticatedRoute>

      <AuthenticatedRoute path="/users">
        <Users />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/reports">
        <Reports />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/reports/:id">
        <ReportDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/categories">
        <Categories />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/infotext">
        <InfotextEdit />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/changePassword">
        <ChangePassword />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="">
        <Dashboard />
      </AuthenticatedRoute>
    </Routes > */
  );
}
