import React, { useEffect, useRef, useState } from 'react';
import svLocale from "date-fns/locale/sv";
import enLocale from "date-fns/locale/en-US";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography, IconButton } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useAppContext } from '../libs/contextLib';
import { getLocaleCatName } from '../libs/helperLib';
import {
  DASHBOARD_DATA_INTERVAL_MONTH,
  DASHBOARD_DATA_INTERVAL_6MONTHS,
  DASHBOARD_DATA_INTERVAL_YEAR,
  DASHBOARD_DATA_INTERVAL_CUSTOM,
} from '../constants/constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(3),
    minWidth: 120,
    maxWidth: 300,
  },
  paper: {
    padding: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end',
  },
  filterText: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  filterTextCustom: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  filterButton: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  yearFilterButton: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  dateFilter: {
    marginRight: theme.spacing(3),
  },
}));

// const dateRanges = [];

/*
let updateDataRange = dateRanges.map((item) => {
  if (item.start === timestampStart) {
    return { ...item, start: timestampStart };
  }
  return item;
}); */

export default function DashboardFilter({
  categories,
  municipalities,
  setDateRange,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  setCategoryFilters,
  municipalityFilters,
  setMunicipalityFilters,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUserMunicipality, i18n } = useAppContext();
  const defaultMunicipalityAdded = useRef(false);

  const handleStartDateChange = (date) => {

    const timestampStart = Date.parse(date);

    if (selectedEndDate <= date) {
      return 
    }

    if (Number.isNaN(timestampStart) === false) {

      setDateRange(DASHBOARD_DATA_INTERVAL_CUSTOM);
      setSelectedStartDate(date);
    }

  };
  const handleEndDateChange = (date) => {

    const timestampEnd = Date.parse(date);

    if (Number.isNaN(timestampEnd) === false) {
      setDateRange(DASHBOARD_DATA_INTERVAL_CUSTOM);
      setSelectedEndDate(date);
    }

  };

  const handleCatFilterChange = (value) => {
    setCategoryFilters(value);
  };

  const handleAddUserMunicipalityOnChange = (value) => {
    setMunicipalityFilters(value);
  };

  function handleClear(e) {
    e.stopPropagation();
    handleStartDateChange(null);
  }
  const localeMap = {
    "en": enLocale,
    "se": svLocale
  };

  useEffect(() => {
    if (!defaultMunicipalityAdded.current && currentUserMunicipality.length && municipalities.length) {
      const defaultMunicipality = municipalities.find((muni) => currentUserMunicipality === muni.name);
      if (defaultMunicipality) {
        setMunicipalityFilters([defaultMunicipality]);
        defaultMunicipalityAdded.current = true;
      }
    }
  });

  return (
      <Paper className={classes.paper}>
        <Box className={classes.filterBox}>
          <Typography className={classes.filterText} color="primary" variant="button">
            {t('filter_data_by')}
          </Typography>
          <FormControl className={classes.formControl}>
            <Autocomplete
              includeInputInList
              multiple
              id="categories"
              options={categories}
              getOptionLabel={(category) => getLocaleCatName(i18n, category)}
              style={{ width: 200 }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label={t('category')} />}
              onChange={(event, newValue) => {
                handleCatFilterChange(newValue.map((cat) => cat.id));
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              includeInputInList
              multiple
              disabled={currentUserMunicipality.length ? true : false}
              id="municipality"
              options={municipalities}
              getOptionLabel={(municipality) => municipality.name}
              style={{ width: 200 }}
              value={municipalityFilters}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label={t('municipality')} />}
              onChange={(event, newValue) => {
                handleAddUserMunicipalityOnChange(newValue.map((municipality) => municipality));
              }}
            />
          </FormControl>
        </Box>
        <Box className={classes.filterBox}>
          <Typography className={classes.filterText} color="primary" variant="button">
            {t('select_date_range')}
          </Typography>
          <Box className={classes.filterButton}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDateRange(DASHBOARD_DATA_INTERVAL_MONTH);
              }}
            >
              {t('month')}
            </Button>
          </Box>
          <Box className={classes.filterButton}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDateRange(DASHBOARD_DATA_INTERVAL_6MONTHS);
              }}
            >
              {t('6months')}
            </Button>
          </Box>
          <Box className={classes.yearFilterButton}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDateRange(DASHBOARD_DATA_INTERVAL_YEAR);
              }}
            >
              {t('year')}
            </Button>
          </Box>
          <Typography className={classes.filterTextCustom} color="primary" variant="button">
            {t('or_custom')}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.default.language]}>
            <KeyboardDatePicker
              disableToolbar
              className={classes.dateFilter}
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="start_date_picker"
              label={t('start_date')}
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={(e) => handleClear(e)}>
                    <ClearRoundedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              maxDate={new Date()}
            />
            <KeyboardDatePicker
              disableToolbar
              className={classes.dateFilter}
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="end_date_picker"
              label={t('end_date')}
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change end date',
              }}
              maxDate={new Date()}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Paper>
  );
}

DashboardFilter.defaultProps = {
  selectedStartDate: undefined,
  selectedEndDate: undefined,
};

DashboardFilter.propTypes = {
  filterData: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.instanceOf(Date),
  setSelectedStartDate: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.instanceOf(Date),
  setSelectedEndDate: PropTypes.func.isRequired,
  setCategoryFilters: PropTypes.func.isRequired,
  municipalityFilters: PropTypes.instanceOf(Array).isRequired,
  setMunicipalityFilters: PropTypes.func.isRequired,
  categories: PropTypes.instanceOf(Array).isRequired,
  municipalities: PropTypes.instanceOf(Array).isRequired,
};
