import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  ListItem,
  List,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  ListSubheader,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../constants/constants';
import { useAppContext } from '../libs/contextLib';
import { getErrorMessage } from '../libs/errorLib';
import { apiPut } from '../libs/apiLib';

const useStyles = makeStyles((theme) => ({
  colortypo: {
    marginBottom: theme.spacing(2),
  },
  nameTextField: {
    marginBottom: theme.spacing(4),
  },
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    minHeight: 400,
    height: 'auto',
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function SubCategoryEdit({ targetCategory, handleClose, refreshData, data }) {
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const [checked, setChecked] = useState([]);
  const [currentSubCategories, setCurrentSubCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const dialogOpen = Boolean(targetCategory);

  const leftChecked = intersection(checked, currentSubCategories);
  const rightChecked = intersection(checked, availableCategories);

  const saveSubCategories = () => {
    const payload = {
      body: {
        id: targetCategory.id,
        subCategories: currentSubCategories.map((cat) => cat.id),
        newAvailableCategoriesList: availableCategories.map((cat) => cat.id),
        parentIdForSubs: targetCategory.id,
      },
    };
    apiPut('/categories', payload)
      .then((response) => {
        refreshData(response);
        setSnackbarType(SNACKBAR_TYPE_SUCCESS);
        setSnackbarMessage(t('category_update_success'));
        setIsSnackbarOpen(true);
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
    handleClose();
    refreshData();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setAvailableCategories(availableCategories.concat(currentSubCategories));
    setCurrentSubCategories([]);
  };

  const handleCheckedRight = () => {
    setAvailableCategories(availableCategories.concat(leftChecked));
    setCurrentSubCategories(not(currentSubCategories, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setCurrentSubCategories(currentSubCategories.concat(rightChecked));
    setAvailableCategories(not(availableCategories, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setCurrentSubCategories(currentSubCategories.concat(availableCategories));
    setAvailableCategories([]);
  };

  useEffect(() => {
    if (dialogOpen) {
      setCurrentSubCategories(targetCategory.subCategories.map((subCatId) => data.find((cat) => cat.id === subCatId)));
      setAvailableCategories(
        data
          .filter((cat) => !cat.parentId && !cat.subCategories.length && cat.id !== targetCategory.id)
          .map((cat) => {
            const newCat = { ...cat };
            delete newCat.tableData;
            return newCat;
          }),
      );
    }
  }, [data, targetCategory, dialogOpen]);
  const customList = (items, title) => (
    <Paper className={classes.paper}>
      <List subheader={<ListSubheader component="div">{title}</ListSubheader>} dense component="div" role="list">
        {items.map((cat) => {
          const labelId = `${cat.id}-label`;

          return (
            <ListItem key={labelId} role="listitem" button onClick={handleToggle(cat)}>
              <ListItemIcon>
                <Checkbox
                  key={labelId}
                  checked={checked.indexOf(cat) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText key={labelId} primary={cat.name.EN} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  return (
    <Dialog maxWidth="xl" open={dialogOpen} onClose={handleClose} aria-labelledby="add-new-category">
      <DialogTitle id="add-new-category">{t('edit_subcategories')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="center" alignItems="flex-start" className={classes.root}>
          <Grid item>{customList(currentSubCategories, 'Selected')}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={currentSubCategories.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={availableCategories.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(availableCategories, 'Available')}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={saveSubCategories} color="primary" variant="contained">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SubCategoryEdit.defaultProps = {
  targetCategory: undefined || null,
};

SubCategoryEdit.propTypes = {
  handleClose: PropTypes.func.isRequired,
  targetCategory: PropTypes.shape({ subCategories: PropTypes.instanceOf(Array), id: PropTypes.string }),
  data: PropTypes.instanceOf(Array).isRequired,
  refreshData: PropTypes.func.isRequired,
};
