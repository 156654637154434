import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../libs/contextLib';
import { apiPost, apiPut } from '../libs/apiLib';
import { getErrorMessage } from '../libs/errorLib';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../constants/constants';

export default function AddCommentDialog(props) {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [isButtonDisabled, disableButton] = useState(true);
  const { reportId, fromSharedReport, open, handleClose, refreshData, passCode } = props;
  const [commentName, setCommentName] = useState('');
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, currentUserEmail } = useAppContext();
  const handleSendComment = (userId) => {
    const name = commentName.length ? commentName : currentUserEmail;
    if (!fromSharedReport) {
      const payload = {
        body: { userId, reportId, content, name },
      };
      return new Promise((resolve) => {
        apiPost('/comments', payload)
          .then(() => {
            refreshData();
            setContent('');
            setSnackbarType(SNACKBAR_TYPE_SUCCESS);
            setSnackbarMessage(t('comment_added'));
            setIsSnackbarOpen(true);
          })
          .then(resolve())
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      });
    }

    // Happens only if fromSharedReport is true
    const fromSharedPayload = {
      body: { passCode, content, name },
    };

    return new Promise((resolve) => {
      setTimeout(() => {
        apiPut(`/editReportStatusAndComment/${reportId}`, fromSharedPayload)
          .then(() => {
            resolve();
            refreshData();
            setContent('');
            setSnackbarType(SNACKBAR_TYPE_SUCCESS);
            setSnackbarMessage(t('report_update_success'));
            setIsSnackbarOpen(true);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      });
    });
  };

  const handleSendCommentClick = () => {
    if (!fromSharedReport) {
      Auth.currentAuthenticatedUser().then((result) => {
        handleSendComment(result.username);
      });
    } else {
      handleSendComment(t('anonymous'));
    }
    handleClose();
    setContent('');
    disableButton(true);
  };

  const handleChange = (event) => {
    setContent(event.target.value);
    disableButton(!event.target.value.length);
  };

  const handleUsernameChange = (event) => {
    setCommentName(event.target.value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose} aria-labelledby="add-comment">
      <DialogTitle id="add-comment">{t('add_new_comment_dialog_title')}</DialogTitle>
      <DialogContent>
        {fromSharedReport && (
          <TextField
            onChange={handleUsernameChange}
            autoFocus={fromSharedReport}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            placeholder=" "
          />
        )}
        <TextField
          autoFocus={!fromSharedReport}
          id="standard-textarea"
          label={t('add_new_comment_dialog_text_field_label')}
          placeholder={t('add_new_comment_dialog_text_field_placeholder')}
          multiline
          rowsMax={6}
          value={content}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button className="reportDetails__popover__triggerButton" color="primary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button
          className="reportDetails__popover__button"
          variant="contained"
          disabled={isButtonDisabled}
          color="primary"
          onClick={handleSendCommentClick}
        >
          {t('reportDetails__sendComment__button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddCommentDialog.defaultProps = {
  fromSharedReport: false,
  refreshData: undefined,
  reportId: undefined,
  passCode: undefined,
};

AddCommentDialog.propTypes = {
  reportId: PropTypes.string,
  fromSharedReport: PropTypes.bool,
  refreshData: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  passCode: PropTypes.string,
};
