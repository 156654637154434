import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SNACKBAR_TYPE_SUCCESS } from '../constants/constants';

function SnackbarComponent(props) {
  const { type, open, message, handleClose } = props;
  const { t } = useTranslation('snackbar');
  return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: type === SNACKBAR_TYPE_SUCCESS ? '#00B557' : 'salmon',
          }}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{message ? t(message) : ''}</span>}
        />
      </Snackbar>
  );
}

SnackbarComponent.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(SnackbarComponent);
