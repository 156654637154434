import config from '../config';

export const getGroups = (currentAuthenticatedUserPayload) => (currentAuthenticatedUserPayload.signInUserSession.accessToken.payload["cognito:groups"]);

export const isSuperadminCheck = (groups) => ( groups.find(group => ( group === config.SUPERADMIN_GROUP_NAME )) );

const isGroupadminCheck = (groups) => ( groups.find(group => ( group === config.GROUPADMIN_GROUP_NAME )) );

export const isAdmin = (groups) => {
    // TryCatch enables us to skip the error caused by groups being undefined when we don't check the from App.js unlike in Login.js
    try {
        if (isSuperadminCheck(groups)) {
            return true;
        }
        if (isGroupadminCheck(groups)) {
            return true;
        }
        return false;
    }
    catch (e) {
        return false;
    }
}