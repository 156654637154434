import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';

// eslint-disable-next-line no-undef
function querystring(name, url = window.location.href) {
  const n = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp(`[?&]${n}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// eslint-disable-next-line react/prop-types
export default function UnauthenticatedRoute({ children }) {
  const { isAuthenticated } = useAppContext();
  const redirect = querystring('redirect');
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    !isAuthenticated ? children : <Navigate to={redirect === '' || redirect === null ? '/' : redirect} />
  );
}
