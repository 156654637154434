import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, TextareaAutosize, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../../libs/contextLib';
import { apiGet, apiPut } from '../../libs/apiLib';
import { getErrorMessage } from '../../libs/errorLib';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../../constants/constants';

export default function InfotextEdit() {
  const [infotext, setInfotext] = useState('');
  const { t } = useTranslation();
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden',
    },
    comments: {
      maxHeight: '200px',
      overflowY: 'scroll',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: 5,
      paddingRight: 5,
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      minHeight: 300,
    },
    paperContainers: {
      margin: theme.spacing(1),
      width: '50%',
    },
    button: {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'flex-end',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    apiGet('/info')
      .then((result) => {
        setInfotext(result.infotext);
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
  }, [setIsSnackbarOpen, setSnackbarMessage, setSnackbarType]);

  
  const handleChange = (event) => {

    const key = event.target.name;
    
    setInfotext({
      ...infotext,
      [event.target.name]: {body: event.target.value, id: infotext[key].id, filename: infotext[key].filename, lang: infotext[key].lang}
    });
  };

  const handleSaveClick = (lang, content, filename) => {
    const payload = { body : { content, file: filename, lang } };
    return new Promise((resolve) => {
      setTimeout(() => {
        apiPut('/info', payload)
          .then(() => {
            resolve();
            setSnackbarType(SNACKBAR_TYPE_SUCCESS);
            setSnackbarMessage(t('infotext_change_success'));
            setIsSnackbarOpen(true);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      });
    });
  };

  return (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Box>
              <Typography variant="h5" component="h1" className={classes.topHeader}>
                {t('infotext_editor')}
              </Typography>
            </Box>
          </Grid>
        {Object.entries(infotext).map(([key, value]) => (
          <Grid item xs={12} container key={key}>
              <Grid item xs={12}>
                <Typography variant="h6">
                {t('info_text_language')}: {value.lang} 
                </Typography>
                <TextareaAutosize
                  name={key}
                  key={key}
                  rowsMin={8}
                  rowsMax={25}
                  aria-label="info text editor"
                  placeholder=""
                  className={classes.paperContainers}
                  // defaultValue={value.body}
                  value={value.body}
                  onChange={handleChange}
                  onBlur={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                <Box className={classes.buttonContainer}>
                  <Box className={classes.button}>
                    <Button
                      size="small"
                      key={value.lang}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSaveClick(value.lang, value.body, value.filename);
                      }}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>

          ))}
        </Grid>
      </Paper>
  );
}
