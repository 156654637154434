import React, { useState, useEffect } from 'react';

// import { useTranslation } from 'react-i18next';
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
  
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { parseISO, getMonth, getYear, eachMonthOfInterval, startOfYear, getDate, subYears, subDays, eachDayOfInterval, endOfMonth, startOfMonth, subMonths} from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  DASHBOARD_DATA_INTERVAL_MONTH,
  DASHBOARD_DATA_INTERVAL_6MONTHS,
  DASHBOARD_DATA_INTERVAL_YEAR,
  DASHBOARD_DATA_INTERVAL_CUSTOM,
} from '../../constants/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function NumberOfCases({ data, range, customStart, customEnd }) {
  const [combinedData, setCombinedData] = useState({});
  const options = {
    maintainAspectRatio: true,
    legend: { display: false },
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  useEffect(() => {
    const generateCasesData = () => {
      let earliestDate = false;
      let latestDate = false;
      const currentDate = new Date();
      const lineData = [];
      const labels = [];
      let useDaysForTicks = true;
      /* eslint-disable no-param-reassign */

      // Set Start and End for ticks in x-axis
      if (range === DASHBOARD_DATA_INTERVAL_MONTH) {
        earliestDate = startOfMonth(subDays(currentDate, 31));
        latestDate = currentDate;
      } else if (range === DASHBOARD_DATA_INTERVAL_6MONTHS) {
        earliestDate = startOfMonth(subMonths(currentDate, 6));
        latestDate = currentDate;
      } else if (range === DASHBOARD_DATA_INTERVAL_YEAR) {
        earliestDate = startOfMonth(subMonths(currentDate, 12));
        latestDate = currentDate;
      } else if (range === DASHBOARD_DATA_INTERVAL_CUSTOM) {
        earliestDate = customStart || startOfYear(subYears(currentDate, 3));
        latestDate = customEnd || endOfMonth(currentDate);
      }

      // Set tick interval objects
      const interval = { start: earliestDate, end: latestDate };
      let intervalObjects = eachDayOfInterval(interval);
      // Use months for ticks if range is 6MONTHS or YEAR, OR custom interval period is longer than 62 days
      if (
        (range === DASHBOARD_DATA_INTERVAL_CUSTOM && intervalObjects.length > 62) ||
        range === DASHBOARD_DATA_INTERVAL_6MONTHS ||
        range === DASHBOARD_DATA_INTERVAL_YEAR
      ) {
        intervalObjects = eachMonthOfInterval(interval);
        useDaysForTicks = false;
      }
      const result = data.reduce((r, { createDate }) => {
        const dateObj = parseISO(createDate);

        const labelKey = useDaysForTicks
          ? JSON.stringify({ day: getDate(dateObj), month: getMonth(dateObj), year: getYear(dateObj) })
          : JSON.stringify({ month: getMonth(dateObj), year: getYear(dateObj) });

        if (!r[labelKey]) {
          r[labelKey] = { key: JSON.parse(labelKey), sum: 1 };
        } else {
          r[labelKey].sum += 1;
        }
        return r;
      }, {});

      for (let i = 0; i < intervalObjects.length; i += 1) {
        const currObjKey = useDaysForTicks
          ? JSON.stringify({ day: getDate(intervalObjects[i]), month: getMonth(intervalObjects[i]), year: getYear(intervalObjects[i]) })
          : JSON.stringify({ month: getMonth(intervalObjects[i]), year: getYear(intervalObjects[i]) });
        if (result[currObjKey]) {
          lineData[i] = result[currObjKey].sum;
        }

        if (useDaysForTicks) {
          labels[i] = `${enUS.localize.month(getMonth(intervalObjects[i]), { width: 'abbreviated' })} ${getDate(intervalObjects[i])}`;
        } else {
          labels[i] = `${enUS.localize.month(getMonth(intervalObjects[i]), { width: 'abbreviated' })} ${getYear(intervalObjects[i])}`;
        }
      }

      setCombinedData({
        labels,
        datasets: [
          {
            data: lineData,
            backgroundColor: '#00A39D',
            barPercentage: 0.5,
            barThickness: 'flex',
            categoryPercentage: 1.0,
            minBarLength: 5,
          },
        ],
      });
    };

    generateCasesData();
  }, [data, customStart, customEnd, range]);
  return (
      <Bar data={combinedData} options={options} />
  );
}

NumberOfCases.defaultProps = {
  customStart: false,
  customEnd: false,
};

NumberOfCases.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      createDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
  range: PropTypes.string.isRequired,
  customStart: PropTypes.instanceOf(Date),
  customEnd: PropTypes.instanceOf(Date),
};
