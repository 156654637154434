import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from './locales/en/translation.json';
import SE from './locales/se/translation.json';

// the translations
const resources = {
  en: {
    translation: EN,
  },
  se: {
    translation: SE,
  },
};

i18n
  // Detect user language
  // Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources,
    // lng: "en",;
    fallbackLng: 'en',
    debug: true,
  });

const t = i18n.t.bind(i18n);
// const tIfExists = (v) => (i18n.exists(v) ? t(v) : null);

export default i18n;
export {
  t,
  // tIfExists
};
