import React, { useState, useEffect, useRef } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import { USER_ROLE_GROUPADMIN, USER_ROLE_SUPERADMIN, USER_ROLE_USER } from '../constants/constants';

export default function AddNewUserDialog({ role, onChange }) {
  const [userRole, setUserRole] = useState('');
  const didMountRef = useRef(false);
  useEffect(() => {
    if (!didMountRef.current) {
      if (role === USER_ROLE_SUPERADMIN) {
        setUserRole(USER_ROLE_SUPERADMIN);
      } else if (role === USER_ROLE_GROUPADMIN) {
        setUserRole(USER_ROLE_GROUPADMIN);
      } else {
        setUserRole(USER_ROLE_USER);
      }
    }
  }, [role]);
  const handleChange = (event) => {
    setUserRole(event.target.value);
    onChange(event.target.value);
  };

  if (userRole === USER_ROLE_USER) {
    return <span>{userRole}</span>;
  }

  return (
    <RadioGroup aria-label="anonymous" name="anonymous" onChange={handleChange} value={role} row>
      <FormControlLabel value="GROUPADMIN" control={<Radio color="primary" />} label="Groupadmin" />
      <FormControlLabel value="SUPERADMIN" control={<Radio color="primary" />} label="Superadmin" />
    </RadioGroup>
  );
}

AddNewUserDialog.propTypes = {
  role: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
