import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../libs/contextLib';
import PageRoutes from '../Routes';
import config from '../config';

const drawerWidth = config.DRAWER_WIDTH;

const useStyles = makeStyles((theme) => ({
  authenticatedContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  unAuthenticatedContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export default function Content() {
  const classes = useStyles();
  const { isSidebarOpen, isAuthenticated } = useAppContext();

  return isAuthenticated ? (
    <main className={clsx(classes.authenticatedContent, { [classes.contentShift]: isSidebarOpen })}>
      <div className={classes.drawerHeader} />
      <PageRoutes />
    </main>
  ) : (
    <main className={clsx(classes.unAuthenticatedContent)}>
      <div className={classes.drawerHeader} />
      <PageRoutes />
    </main>
  );
}
