import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PeopleIcon from '@material-ui/icons/People';
import ViewListIcon from '@material-ui/icons/ViewList';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import config from '../config';
import { useAppContext } from '../libs/contextLib';

const drawerWidth = config.DRAWER_WIDTH;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const ListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: '#FBFBFB',
      color: '#00A39D',
    },
    '&$selected:hover': {
      color: '#00A39D',
      backgroundColor: '#FBFBFB',
    },
    '&:hover': {
      color: '#00A39D',
      backgroundColor: '#FBFBFB',
    },
  },
  selected: {},
})(MuiListItem);

export default function Sidebar() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { isSuperadmin, isSidebarOpen, setIsSidebarOpen } = useAppContext();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname;
  const handleDrawerClose = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const selectedElement = () => {
      let retVal = 0;
      if (currentPath.indexOf('reports') > -1) {
        retVal = 1;
      } else if (currentPath.indexOf('categories') > -1) {
        retVal = 2;
      } else if (currentPath.indexOf('users') > -1) {
        retVal = 3;
      } else if (currentPath.indexOf('infotext') > -1) {
        retVal = 4;
      }
      return retVal;
    };
    setSelected(selectedElement);
  }, [currentPath]);

  const handleListItemClick = (path, selelectedNum) => {
    setSelected(selelectedNum);
    navigate(path);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
      </div>
      <Divider />
      <List>
        <ListItem selected={selected === 0} button onClick={() => handleListItemClick('/', 0)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={t('dashboard')} />
        </ListItem>
        <ListItem selected={selected === 1} button onClick={() => handleListItemClick('/reports', 1)}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={t('reports')} />
        </ListItem>
        {isSuperadmin && (
          <ListItem selected={selected === 2} button onClick={() => handleListItemClick('/categories', 2)}>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItem} primary={t('sidebar_categories')} />
          </ListItem>
        )}
        <ListItem selected={selected === 3} button onClick={() => handleListItemClick('/users', 3)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText className={classes.listItem} primary={t('users')} />
        </ListItem>
        {isSuperadmin && (
          <ListItem selected={selected === 4} button onClick={() => handleListItemClick('/infotext', 4)}>
            <ListItemIcon>
              <TextFieldsIcon />
            </ListItemIcon>
            <ListItemText className={classes.listItem} primary={t('edit_infotext')} />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
}