import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  CircularProgress,
  Button,
  Paper,
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Box
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { grey } from '@material-ui/core/colors';
import { useAppContext } from '../../libs/contextLib';
import { refreshData, apiPut, apiGet, apiDelete } from '../../libs/apiLib';
import GoogleMaps from '../../components/GoogleMaps';
import AddCommentDialog from '../../components/AddCommentDialog';
import ShareReportDialog from '../../components/ShareReportDialog';
import Comments from '../../components/Comments';
import { getErrorMessage } from '../../libs/errorLib';
import { formatDateToStr, getLocaleCatName } from '../../libs/helperLib';
import '../../styles/ReportDetails.css';
import {
  REPORT_STATUS_IN_PROGRESS,
  REPORT_STATUS_OPEN,
  REPORT_STATUS_DONE,
  SNACKBAR_TYPE_SUCCESS,
  SNACKBAR_TYPE_ERROR,
  COMMENT_ANONYMOUS_USERNAME,
} from '../../constants/constants';


export default function ReportDetails() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [reporterDetails, setReporterDetails] = useState([]);
  const { isAuthenticated, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, i18n } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [isCommentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const [isShareReportDialogOpen, setShareReportDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [status, setStatus] = useState({});
  const [category, setCategory] = useState({});
  const [parentCategoryObject, setParentCategoryObject] = useState({});
  const [parentCategory, setParentCategory] = useState("");
  const [isParentCategory, setIsParentCategory] = useState(false);

  const handleDeleteClickOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteClickClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const refreshDataWithParams = async () => {
    await refreshData(`/reports/${id}`, isAuthenticated, setData, setIsLoading, setIsParentCategory);
  };

  useEffect(() => {
    refreshDataWithParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
    },
    paperContainers: {
      margin: theme.spacing(1),
    },
    topHeader: {
      padding: `0 0 ${theme.spacing(2)}px 0`,
      width: '50%',
      display: 'inline-block',
    },
    delButton: {
      float: 'right',
      display: 'inline-block',
    },
    bodyHeader: {
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
      width: '100%',
    },
    reportImage: {
      width: '100%',
    },
    noImageUlItem: {
      display: 'inline',
    },
    noImageItems: {
      flexGrow: 1,
      textAlign: 'center',
      fontSize: 30,
    },
    noImageListItem: {
      height: '75%',
      top: '1.5vh',
      position: 'relative',
      justifyItems: 'center',
      alignSelf: 'center',
      background: grey[100],
    },
    statusSelectText: {
      marginRight: theme.spacing(1),
    },
    saveStatusButton: {
      margin: theme.spacing(1),
    },
    titleContainer: {
      width: '100%',
    },
  }));

  const classes = useStyles();
  useEffect(() => {

    const userDetails = [];

    if (data.userId) {
      apiGet(`/users/${data.userId}`)
        .then((result) => {
          for (const item of result.UserAttributes) {
            const itemKey = item.Name;
            const itemValue = item.Value;
            userDetails[itemKey] = itemValue;
          }
          setReporterDetails(userDetails);
        })
        .catch((e) => {
          setSnackbarType(SNACKBAR_TYPE_ERROR);
          setSnackbarMessage(getErrorMessage(e));
          setIsSnackbarOpen(true);
        });
    }

    /*
    if (data.userId) {
      apiGet(`/uidauth/${data.userId}/users`)
        .then((result) => {
          console.log(data);
          const reporterData = result.find((u) => u.sub === data.userId);
          setReporterDetails(reporterData);
        })
        .catch((e) => {
          setSnackbarType(SNACKBAR_TYPE_ERROR);
          setSnackbarMessage(getErrorMessage(e));
          setIsSnackbarOpen(true);
        });
    }
    setStatus(data.status); */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSnackbarType, setSnackbarMessage, setIsSnackbarOpen]);


  useEffect(() => {
    apiGet('/users')
      .then((result) => {
        if (data.comments) {
          setCommentData(
            data.comments.map((comment) => {
              let username = comment.name;
              if (!username) {
                username = COMMENT_ANONYMOUS_USERNAME;
                if (comment.userId) {
                  const user = result.find((u) => u.sub === comment.userId);
                  if (user) {
                    username = user.email;
                  }
                }
              }
              return { id: comment.id, user: username, date: comment.createdAt, content: comment.content };
            }),
          );
        }
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });

    setStatus(data.status);

    if (data.categoryId) {
      const apiGetCategory = async () => {
        await apiGet(`/categories/${data.categoryId}`)
          .then((result) => {
            setCategory(result);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      };
      apiGetCategory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSnackbarType, setSnackbarMessage, setIsSnackbarOpen]);
  useEffect(() => {
    if (category.parentId) {
      const apiGetParentCategory = async () => {
        await apiGet(`/categories/${category.parentId}`)
          .then((result) => {
            const parentCategories = result.name;
            setParentCategoryObject(parentCategories);
            const pageLanguage = i18n.default.language.toUpperCase();
            const parentCategoryName = parentCategories[pageLanguage];
            setParentCategory(parentCategoryName);
            setIsParentCategory(true);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      };
      apiGetParentCategory();
    }
  }, [category, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, i18n]);


  i18next.on('languageChanged', () => {
    if (isParentCategory) {
      setParentCategory(parentCategoryObject[i18n.default.language.toUpperCase()]);
      return;
    }
    setParentCategory("");
  });

  const handleCommentsOpen = () => {
    setCommentsDialogOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsDialogOpen(false);
  };
  const handleShareReportOpen = () => {
    setShareReportDialogOpen(true);
  };

  const handleShareReportClose = () => {
    setShareReportDialogOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const updateStatus = async () => {
    let currUserId = '0';
    let currUserEmail = '';
    if (status !== data.status.toLowerCase()) {
      await Auth.currentAuthenticatedUser().then((result) => {
        currUserId = result.username;
        currUserEmail = result.attributes.email;
      });
      const payload = {
        body: { status, categoryId: data.categoryId, userId: currUserId, name: currUserEmail },
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          apiPut(`/reports/${data.id}`, payload)
            .then(() => {
              resolve();
              setSnackbarType(SNACKBAR_TYPE_SUCCESS);
              setSnackbarMessage(t('report_update_success'));
              setIsSnackbarOpen(true);
              refreshDataWithParams();
            })
            .catch((e) => {
              setSnackbarType(SNACKBAR_TYPE_ERROR);
              setSnackbarMessage(getErrorMessage(e));
              setIsSnackbarOpen(true);
            });
        });
      });
    }
    return true;
  };

  return (
    <>
      {isLoading || !category.name ? (
        <CircularProgress size={40} left={-20} top={10} status="loading" style={{ marginLeft: '50%', marginTop: '10%' }} />
      ) : (
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <Box className={classes.titleContainer}>
                <Typography variant="h5" component="h1" className={classes.topHeader}>
                  {t('report_details')}
                </Typography>
                <Button variant="contained" size="small" color="primary" className={classes.delButton} onClick={handleDeleteClickOpen}>
                  {t('delete')}
                </Button>
                <DeleteDialog
                  deleteDialogOpen={isDeleteDialogOpen}
                  handleDeleteDialogClose={handleDeleteClickClose}
                  reportId={id}
                  classes={classes}
                />
              </Box>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers} style={{ height: '100%' }}>
                  <List>
                    <ListSubheader>{t('report_data')}</ListSubheader>
                    <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('municipality')}: ${data.municipality}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('name')}: ${reporterDetails.name}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('phone_number')}: ${reporterDetails.phone_number}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('email')}: ${data.userEmail}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('creation_date')}: ${formatDateToStr(new Date(data.createDate))}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      {isParentCategory ? (
                        <ListItemText
                          primary={`${t('category')}: ${parentCategory} > ${getLocaleCatName(i18n, category)}`}
                        />) :
                        (<ListItemText
                          primary={`${t('category')}: ${getLocaleCatName(i18n, category)}`}
                        />)
                      }
                    </ListItem>
                      <ListItem style={{ paddingTop: 0 }}>
                      <ListItemText primary={`${t('address')}: ${data.address}`} />
                    </ListItem>
                    <ListItem>
                      <Typography className={classes.statusSelectText}>{t('status')}:</Typography>
                      <Select labelId="Status" id="status-select" value={status.toLowerCase()} onChange={handleStatusChange}>
                        <MenuItem value={REPORT_STATUS_OPEN}>{t('open')}</MenuItem>
                        <MenuItem value={REPORT_STATUS_IN_PROGRESS}>{t('in_progress')}</MenuItem>
                        <MenuItem value={REPORT_STATUS_DONE}>{t('done')}</MenuItem>
                      </Select>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.saveStatusButton}
                        onClick={updateStatus}
                      >
                        {t('update_status')}
                      </Button>
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers} style={{ height: '100%' }}>
                  <List>
                    <ListSubheader>{t('report_location')}</ListSubheader>
                    <ListItem style={{ paddingTop: 0 }}>
                      <GoogleMaps width="100%" height="300px" latitude={data.latitude} longitude={data.longitude} />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers} style={{ height: '100%' }}>
                  <List className={!data.imageUrl && classes.noImageUlItem}>
                    <ListSubheader>{t('report_image')}</ListSubheader>
                    <ListItem className={!data.imageUrl && classes.noImageListItem} style={{ paddingTop: 0, maxHeight: '300px', overflow: 'hidden' }}>
                      {data.imageUrl ? (
                        <img className={classes.reportImage} src={data.imageUrl} alt="Report" />
                      ) : (
                        <div className={classes.noImageItems}>
                          <ImageSearchIcon style={{ fontSize: 50, color: grey[500] }} />{' '}
                          <Typography variant="h6" style={{ color: grey[500] }}>
                            {t('no_image')}
                          </Typography>
                        </div>
                      )}
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Typography variant="h5">{t('comments')}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.comments}>
              <Comments data={commentData} fullPage />
            </Grid>
            <Grid item xs={12} container direction="row" justify="center" alignItems="center">
              <Link style={{ textDecoration: 'none' }} to="/reports">
                <Button className="reportDetails__popover__triggerButton" color="primary">
                  {t('see_all_reports')}
                </Button>
              </Link>
              <Button variant="contained" color="primary" onClick={handleCommentsOpen}>
                {t('reportDetails__addComment__button')}
              </Button>
              <Button className="reportDetails__popover__button" variant="contained" color="primary" onClick={handleShareReportOpen}>
                {t('shareReport_button')}
              </Button>
              <AddCommentDialog
                open={isCommentsDialogOpen}
                handleClose={handleCommentsClose}
                reportId={id}
                refreshData={() => refreshDataWithParams()}
              />
              <ShareReportDialog open={isShareReportDialogOpen} handleClose={handleShareReportClose} reportId={id} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

function DeleteDialog({ deleteDialogOpen, handleDeleteDialogClose, reportId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const deleteReport = async () => {
    await apiDelete(`/reports/${reportId}`)
      .then(() => {
        setSnackbarType(SNACKBAR_TYPE_SUCCESS);
        setSnackbarMessage(t('delete_successful'));
        setIsSnackbarOpen(true);
        handleDeleteDialogClose();
        navigate('/');
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      });
  };

  return (
    <>
      <Dialog maxWidth="xs" fullWidth open={deleteDialogOpen} onClose={handleDeleteDialogClose} aria-labelledby="add-new-category">
        <DialogTitle id="add-new-category">{t('delete')}</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h6" display="block" gutterBottom>
            {t('delete_confirmation')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} variant="contained" color="primary">
            {t('no')}
          </Button>
          <Button onClick={deleteReport} variant="contained" color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteDialog.propTypes = {
  deleteDialogOpen: PropTypes.bool.isRequired,
  handleDeleteDialogClose: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
};
