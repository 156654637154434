import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { useParams } from 'react-router-dom';
import GoogleMaps from '../../components/GoogleMaps';
import Comments from '../../components/Comments';
import AddCommentDialog from '../../components/AddCommentDialog';
import { useAppContext } from '../../libs/contextLib';
import { refreshData, apiPut, apiGet } from '../../libs/apiLib';
import { getErrorMessage } from '../../libs/errorLib';
import { formatDateToStr, getLocaleCatName } from '../../libs/helperLib';
import {
  REPORT_STATUS_IN_PROGRESS,
  REPORT_STATUS_OPEN,
  REPORT_STATUS_DONE,
  SNACKBAR_TYPE_SUCCESS,
  SNACKBAR_TYPE_ERROR,
} from '../../constants/constants';
import '../../styles/ReportDetails.css';

export default function SharedReport() {
  const { t } = useTranslation();
  const [data, setData] = useState('');
  const { isAuthenticated, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, i18n } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isCommentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const [status, setStatus] = useState({});
  const [category, setCategory] = useState({});
  const [commentData, setCommentData] = useState([]);
  const { id, passCode } = useParams();
  const [parentCategory, setParentCategory] = useState({});
  const [isParentCategory, setIsParentCategory] = useState(false);

  const refreshDataWithParams = async () => refreshData(`/reports/${id}/share/${passCode}`, true, setData, setIsLoading);
  useEffect(() => {
    refreshDataWithParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (data.categoryId) {
      const apiGetCategory = async () => {
        await apiGet(`/categories/${data.categoryId}`)
          .then((result) => {
            setCategory(result);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      };
      apiGetCategory();
    }
  }, [data, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType]);

  useEffect(() => {
    if (category.parentId) {
      const apiGetParentCategory = async () => {
        await apiGet(`/categories/${category.parentId}`)
          .then((result) => {
            setParentCategory(result);
            setIsParentCategory(true);
          })
          .catch((e) => {
            setSnackbarType(SNACKBAR_TYPE_ERROR);
            setSnackbarMessage(getErrorMessage(e));
            setIsSnackbarOpen(true);
          });
      };
      apiGetParentCategory();
    }
  }, [category, setIsSnackbarOpen, setSnackbarMessage, setSnackbarType, i18n]);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
    },
    paperContainers: {
      margin: theme.spacing(1),
    },
    topHeader: {
      padding: `0 0 ${theme.spacing(2)}px 0`,
      width: '100%',
    },
    bodyHeader: {
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
      width: '100%',
    },
    reportImage: {
      width: '100%',
    },
    noImageUlItem: {
      display: 'inline',
    },
    noImageItems: {
      flexGrow: 1,
      textAlign: 'center',
      fontSize: 30,
    },
    noImageListItem: {
      height: '75%',
      top: '1.5vh',
      position: 'relative',
      justifyItems: 'center',
      alignSelf: 'center',
      background: grey[100],
    },
    statusSelectText: {
      marginRight: theme.spacing(1),
    },
    saveStatusButton: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (data.comments) {
      setCommentData(
        data.comments.map((comment) => {
          const username = comment.name ? comment.name : t('name_missing');
          return { id: comment.id, user: username, date: comment.createdAt, content: comment.content };
        }),
      );
    }
    setStatus(data.status);
  }, [data, t]);

  const handleCommentsOpen = () => {
    setCommentsDialogOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsDialogOpen(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const updateStatus = () => {
    if (status !== data.status.toLowerCase()) {
      const payload = {
        body: { status, passCode, content: 'Status change' },
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          apiPut(`/editReportStatusAndComment/${data.id}`, payload)
            .then(() => {
              resolve();
              setSnackbarType(SNACKBAR_TYPE_SUCCESS);
              setSnackbarMessage(t('report_update_success'));
              setIsSnackbarOpen(true);
              refreshDataWithParams();
            })
            .catch((e) => {
              setSnackbarType(SNACKBAR_TYPE_ERROR);
              setSnackbarMessage(getErrorMessage(e));
              setIsSnackbarOpen(true);
            });
        });
      });
    }
    return true;
  };

  return (
    <>
      {isLoading || !category.name ? (
        <CircularProgress size={40} left={-20} top={10} status="loading" style={{ marginLeft: '50%', marginTop: '10%' }} />
      ) : (
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <Typography variant="h5" component="h1" className={classes.topHeader}>
                {t('report_details')}
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers}>
                  <List>
                    <ListSubheader>{t('report_data')}</ListSubheader>
                    <ListItem>
                      <ListItemText primary={`${t('municipality')}: ${data.municipality}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`${t('report_id')}: ${data.id}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`${t('user')}: ${data.userEmail}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`${t('creation_date')}: ${formatDateToStr(new Date(data.createDate))}`} />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0 }}>
                      {isParentCategory ? (
                        <ListItemText
                          primary={`${t('category')}: ${getLocaleCatName(i18n, parentCategory)} > ${getLocaleCatName(i18n, category)}`}
                        />
                      ) : (
                        <ListItemText primary={`${t('category')}: ${getLocaleCatName(i18n, category)}`} />
                      )}
                    </ListItem>
                    <ListItem>
                      <Typography className={classes.statusSelectText}>{t('status')}:</Typography>
                      <Select labelId="Status" id="status-select" value={status.toLowerCase()} onChange={handleStatusChange}>
                        <MenuItem value={REPORT_STATUS_OPEN}>{t('open')}</MenuItem>
                        <MenuItem value={REPORT_STATUS_IN_PROGRESS}>{t('in_progress')}</MenuItem>
                        <MenuItem value={REPORT_STATUS_DONE}>{t('done')}</MenuItem>
                      </Select>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.saveStatusButton}
                        onClick={updateStatus}
                      >
                        {t('update_status')}
                      </Button>
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers}>
                  <List>
                    <ListSubheader>{t('report_location')}</ListSubheader>
                    <ListItem>
                      <GoogleMaps width="100%" height="300px" latitude={data.latitude} longitude={data.longitude} />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paperContainers}>
                  <List className={!data.imageUrl && classes.noImageUlItem}>
                    <ListSubheader>{t('report_image')}</ListSubheader>
                    <ListItem className={!data.imageUrl && classes.noImageListItem}>
                      {data.imageUrl ? (
                        <img className={classes.reportImage} src={data.imageUrl} alt="Report" />
                      ) : (
                        <div className={classes.noImageItems}>
                          <ImageSearchIcon style={{ fontSize: 50, color: grey[500] }} />{' '}
                          <Typography variant="h6" style={{ color: grey[500] }}>
                            {t('no_image')}
                          </Typography>
                        </div>
                      )}
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Typography variant="h5">{t('comments')}</Typography>
            </Grid>
            <Grid item xs={12} container spacing={2} className={classes.comments}>
              <Comments data={commentData} fullPage />
            </Grid>
            <Grid item xs={12} container direction="row" justify="center" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleCommentsOpen}>
                {t('reportDetails__addComment__button')}
              </Button>
              <AddCommentDialog
                open={isCommentsDialogOpen}
                handleClose={handleCommentsClose}
                reportId={id}
                refreshData={() => refreshDataWithParams()}
                passCode={passCode}
                fromSharedReport
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
