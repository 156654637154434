export const SNACKBAR_TYPE_SUCCESS = 'success';
export const SNACKBAR_TYPE_ERROR = 'error';
export const REPORT_STATUS_IN_PROGRESS = 'in progress';
export const REPORT_STATUS_OPEN = 'open';
export const REPORT_STATUS_DONE = 'done';
export const DASHBOARD_DATA_INTERVAL_MONTH = 'MONTH';
export const DASHBOARD_DATA_INTERVAL_6MONTHS = '6MONTHS';
export const DASHBOARD_DATA_INTERVAL_YEAR = 'YEAR';
export const DASHBOARD_DATA_INTERVAL_CUSTOM = 'CUSTOM';
export const USER_ROLE_SUPERADMIN = 'SUPERADMIN';
export const USER_ROLE_GROUPADMIN = 'GROUPADMIN';
export const USER_ROLE_USER = 'USER';
export const COMMENT_ANONYMOUS_USERNAME = 'Anonymous user';
