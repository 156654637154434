import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import InboxIcon from '@material-ui/icons/Inbox';
import UpdateIcon from '@material-ui/icons/Update';
import DoneIcon from '@material-ui/icons/Done';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../libs/contextLib';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import PatchedPagination from '../../components/PatchedPagination';
import { refreshData } from '../../libs/apiLib';
import { formatDateToStr, getLocaleCatName } from '../../libs/helperLib';
import { REPORT_STATUS_IN_PROGRESS, REPORT_STATUS_OPEN } from '../../constants/constants';

export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { isAuthenticated, currentUserMunicipality, i18n } = useAppContext();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden',
    },
    comments: {
      maxHeight: '200px',
      overflowY: 'scroll',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: 5,
      paddingRight: 5,
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      minHeight: 300,
    },
  }));
  const classes = useStyles();
  const getItemStatus = (itemStatus) => {
    const status = itemStatus.toLowerCase();
    switch (status) {
      case REPORT_STATUS_IN_PROGRESS:
        return (
          <div className="status progress">
            <div className="content">
              <UpdateIcon />
              <span>{t('in_progress')}</span>
            </div>
          </div>
        );
      case REPORT_STATUS_OPEN:
        return (
          <div className="status received">
            <div className="content">
              <InboxIcon />
              <span>{t('received')}</span>
            </div>
          </div>
        );
      default:
        return (
          <div className="status resolved">
            <div className="content">
              <DoneIcon />
              <span>{t('resolved')}</span>
            </div>
          </div>
        );
    }
  };
  const formatReportsData = (dataToBeFormatted) => {
    const formattedData = dataToBeFormatted.map((item) => ({
      createDate: item.createDate,
      latitude: item.latitude,
      longitude: item.longitude,
      comments: item.comments && item.comments.length > 0 ? item.comments : [],
      municipality: item.municipality,
      user: item.userEmail,
      status: item.status || t('status_missing'),
      imageUrl: item.imageUrl,
      id: item.id,
      categoryId: item.categoryId,
      categoryName: categories.length
        ? getLocaleCatName(
          i18n,
          categories.find((cat) => cat.id === item.categoryId),
        )
        : undefined,
    }));
    return formattedData;
  };

  useEffect(() => {
    const refreshReportData = async () => refreshData('/reports/allreports/true', isAuthenticated, setData, setIsLoading);
    const getCategories = async () => refreshData('/categories', isAuthenticated, setCategories, setIsLoading);
    refreshReportData();
    getCategories();
  }, [isAuthenticated]);

  const navigate = useNavigate();

  return (
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2} m={0}>
          <Grid item xs={12} md={12}>
            {isLoading ? (
              <CircularProgress size={40} left={-20} top={10} status="loading" style={{ marginLeft: '50%', marginTop: '10%' }} />
            ) : (
              <CustomMaterialTable
                columns={[
                  {
                    title: t('category'),
                    field: 'categoryName',
                    filtering: false,
                  },
                  {
                    title: t('report_column_createdAt'),
                    field: 'createDate',
                    type: 'date',
                    filtering: false,
                    defaultSort: 'desc',
                    render: (rowData) => formatDateToStr(new Date(rowData.createDate)),
                  },
                  { title: t('user_column_email'), field: 'user', filtering: false },
                  { title: t('report_column_municipality'), field: 'municipality', filtering: false },
                  {
                    title: t('report_column_status'),
                    field: 'status',
                    filtering: false,
                    render: (rowData) => getItemStatus(rowData.status),
                    cellStyle: {
                    }
                  },
                ]}
                data={formatReportsData(data)}
                title={t('reports_table_title')}
                options={{
                  search: true,
                  pageSize: 10,
                  pageSizeOptions: [],
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: `Reports export ${formatDateToStr(new Date())}`,
                  searchText: currentUserMunicipality,
                }}
                components={{
                  Pagination: PatchedPagination,
                }}
                onRowClick={(event, rowData) => {
                  // console.log(rowData.id);
                  navigate(`/reports/${rowData.id}`);
                  // navigate(`/`);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
  );
}
