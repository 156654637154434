import React, { useState, useEffect, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { ThemeProvider, CssBaseline } from '@material-ui/core/';
import { AppContext } from './libs/contextLib';
import { getErrorMessage } from './libs/errorLib';
import { apiGet } from './libs/apiLib';
import { getGroups, isAdmin, isSuperadminCheck } from './libs/loginLib';
import { SNACKBAR_TYPE_ERROR } from './constants/constants';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Snackbar from './components/Snackbar';

const i18n = require('./i18n');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#00A39D',
    },
  },
});

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [cognitoUser, setCognitoUser] = useState({});
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserMunicipality, setCurrentUserMunicipality] = useState('');

  // Disabled rule because the called function is async which won't work inside useEffect as suggested by eslint
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function onLoad() {
    try {
      await Auth.currentSession();
      Auth.currentAuthenticatedUser().then((result) => {
        setCurrentUserEmail(result.attributes.email);
        const groups = getGroups(result);
        if (isAdmin(groups)) {
          setIsAuthenticated(true);
          if (isSuperadminCheck(groups)) {
            setIsSuperadmin(true);
          }
          if (!isSuperadminCheck(groups)) {
            apiGet(`/users/${result.username}`)
              .then((userresult) => {
                if (userresult) {
                  const municipality = userresult.UserAttributes.find((obj) => obj.Name === 'custom:Municipality').Value;
                  if (municipality) {
                    setCurrentUserMunicipality(municipality);
                  }
                }
              })
              .catch((e) => {
                setSnackbarType(SNACKBAR_TYPE_ERROR);
                setSnackbarMessage(getErrorMessage(e));
                setIsSnackbarOpen(true);
              });
          }
        }
      });
    } catch (e) {
      if (e !== 'No current user') {
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
      }
      // Force sidebar closed to set site width correctly. Otherwise sidebar cause the site to be too wide and views won't stay centered.
      setIsSidebarOpen(false);
      // Set all states back to default if auth fails
      setIsAuthenticated(false);
      setIsSuperadmin(false);
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleSnackbarClose = (reason) => {
    // Hide only on timeout or on click
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const classes = useStyles();

  const contextValues = useMemo(
    () => ({
      isAuthenticated,
          setIsAuthenticated,
          isSuperadmin,
          setIsSuperadmin,
          isSidebarOpen,
          setIsSidebarOpen,
          cognitoUser,
          setCognitoUser,
          setIsSnackbarOpen,
          setSnackbarMessage,
          setSnackbarType,
          currentUserEmail,
          currentUserMunicipality,
          i18n
    }),
    [isAuthenticated,
      setIsAuthenticated,
      isSuperadmin,
      setIsSuperadmin,
      isSidebarOpen,
      setIsSidebarOpen,
      cognitoUser,
      setCognitoUser,
      setIsSnackbarOpen,
      setSnackbarMessage,
      setSnackbarType,
      currentUserEmail,
      currentUserMunicipality,
      i18n]
  );

  return (
    !isAuthenticating && (
      <AppContext.Provider
        value={contextValues}
      >
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <Topbar userEmail={currentUserEmail} i18n={i18n} />
            <Snackbar open={isSnackbarOpen} message={snackbarMessage} type={snackbarType} handleClose={handleSnackbarClose} />
            {isAuthenticated ? <Sidebar /> : null}
            <Content />
          </div>
        </ThemeProvider>
      </AppContext.Provider>
    )
  );
}
