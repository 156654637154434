import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../libs/errorLib';
import { apiPost } from '../libs/apiLib';
import { useAppContext } from '../libs/contextLib';
import { SNACKBAR_TYPE_ERROR, SNACKBAR_TYPE_SUCCESS } from '../constants/constants';

const useStyles = makeStyles((theme) => ({
  superAdminButton: {
    marginTop: theme.spacing(1),
    marginLeft: 2,
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AddNewUserDialog({ refreshData, handleClose, open, municipalities, newUserAdded }) {
  const classes = useStyles();
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();
  const [addUserEmail, setAddUserEmail] = useState('');
  const [addUserMunicipality, setAddUserMunicipality] = useState('');
  const [addUserSetAsSuperadmin, setAddUserSetAsSuperadmin] = useState(false);
  const [addUserRole, setUserRole] = useState('groupadmin');
  const [errors, setErrors] = useState({ email: false, municipality: false });
  const { t } = useTranslation();

  const validateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
  };

  const validateEmailField = () => {
    let newErrorsObj = {};
    if (!validateEmail(addUserEmail) && addUserEmail !== '') {
      setTimeout(() => {
        newErrorsObj = { ...errors, email: true };
        setErrors(newErrorsObj);
      }, 100);
      return false;
    }
    newErrorsObj = { ...errors, email: false };
    setErrors(newErrorsObj);
    return true;
  };

  const validateMunicipalityField = () => {
    if (!addUserMunicipality.length) {
      setTimeout(() => {
        const newErrorsObj = { ...errors, municipality: true };
        setErrors(newErrorsObj);
      }, 100);
    } else {
      const newErrorsObj = { ...errors, municipality: false };
      setErrors(newErrorsObj);
    }
  };

  const handleAddNewUserClick = () => {
    if (!validateEmailField) {
      const newErrorsObj = { ...errors, municipality: true };
      setErrors(newErrorsObj);
    } else {
      const payload = {
        body: { email: addUserEmail, municipality: addUserMunicipality, setAsSuperadmin: addUserSetAsSuperadmin },
      };
      apiPost('/users', payload)
        .then((response) => {
          refreshData(response);
          setSnackbarType(SNACKBAR_TYPE_SUCCESS);
          setSnackbarMessage(t('user_added_success'));
          setIsSnackbarOpen(true);
          newUserAdded();
        })
        .catch((e) => {
          setSnackbarType(SNACKBAR_TYPE_ERROR);
          setSnackbarMessage(getErrorMessage(e));
          setIsSnackbarOpen(true);
        });
      handleClose();
    }
  };

  const handleAddUserEmailOnChange = (event) => {
    setAddUserEmail(event.target.value);
    if (validateEmail(event.target.value)) {
      const newErrorsObj = { ...errors, email: false };
      setErrors(newErrorsObj);
    }
  };

  const handleAddUserMunicipalityOnChange = (value) => {
    setAddUserMunicipality(value);
    const newErrorsObj = { ...errors, municipality: false };
    setErrors(newErrorsObj);
  };

  const handleRoleChange = (event) => {
    const userRole = event.target.value;
    setUserRole(userRole);
    if(userRole === 'superadmin') {
      setAddUserSetAsSuperadmin(true);
      return;
    } 
      setAddUserSetAsSuperadmin(false);
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="add-new-user">
      <DialogTitle id="add-new-user">{t('add_user')}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={handleAddUserEmailOnChange}
          autoFocus
          margin="dense"
          id="name"
          label={t('email_address')}
          type="email"
          fullWidth
          placeholder=" "
          onBlur={validateEmailField}
          error={errors.email && addUserEmail !== ''}
          helperText={errors.email && addUserEmail !== '' ? 'Enter valid email' : ''}
          className={classes.formRow}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Select user role</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={addUserRole} defaultValue={addUserRole} onChange={handleRoleChange}>
          <FormControlLabel value="groupadmin" control={<Radio color="primary"/>} label="Groupadmin" />
          <FormControlLabel value="superadmin" control={<Radio color="primary"/>} label="Superadmin" />
          </RadioGroup>
        </FormControl>
        {!addUserSetAsSuperadmin &&
        <Autocomplete
          includeInputInList
          id="municipality"
          options={municipalities}
          getOptionLabel={(municipality) => municipality.name}
          style={{ width: 300 }}
          // TODO: fix this eslint error later
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} label="Municipality" />}
          onChange={(event, newValue) => {
            if (newValue) {
              handleAddUserMunicipalityOnChange(newValue.name);
            } else {
              handleAddUserMunicipalityOnChange('');
            }
          }}
          onBlur={validateMunicipalityField}
          className={classes.formRow}
        />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleAddNewUserClick}
          color="primary"
          disabled={!addUserEmail.length || (!addUserMunicipality.length && !addUserSetAsSuperadmin) || errors.email || errors.addUserMunicipality}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddNewUserDialog.propTypes = {
  refreshData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  municipalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
