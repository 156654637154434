import { API } from 'aws-amplify';
import { onError } from './errorLib';

export const API_NAME = 'hsrAPI';

export async function apiGet(path, payload) {
  return API.get(API_NAME, path, payload);
}

export async function apiPost(path, payload) {
  return API.post(API_NAME, path, payload);
}

export async function apiPut(path, payload) {
  return API.put(API_NAME, path, payload);
}

export async function apiDelete(path) {
  return API.del(API_NAME, path);
}

export const refreshData = async (path, isAuthenticated, setData, setIsLoading) => {
  if (!isAuthenticated) {
    return;
  }
  try {
    const data = (await API.get(API_NAME, path)) || [];
    setData(data);
  } catch (e) {
    onError(e);
  }
  if (setIsLoading) {
    setIsLoading(false);
  }
};
