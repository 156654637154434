import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';

export default function ColorPicker({ categoryColorHex, onChange }) {
  const [color, setColor] = useState(categoryColorHex);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (selectedColor) => {
    setColor(selectedColor.hex);
    onChange(selectedColor.hex);
  };

  const id = displayColorPicker ? 'colorpicker' : undefined;

  return (
    <div>
      <div
        style={{
          backgroundColor: color,
          width: '65px',
          height: '20px',
          cursor: 'pointer',
          borderRadius: 8,
        }}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        aria-label="Open color chooser"
        tabIndex={0}
      />

      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
            onKeyDown={handleClose}
            role="button"
            aria-label="Close color chooser"
            tabIndex={0}
          />
          <Popover
            id={id}
            open={displayColorPicker}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ChromePicker color={color} onChange={handleChange} />
          </Popover>
        </div>
      ) : null}
    </div>
  );
}

ColorPicker.propTypes = {
  categoryColorHex: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
