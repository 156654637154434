import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import '../../styles/Forms.css';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { getErrorMessage } from '../../libs/errorLib';
import { getGroups, isAdmin, isSuperadminCheck } from '../../libs/loginLib';
import { useAppContext } from '../../libs/contextLib';
import { SNACKBAR_TYPE_ERROR } from '../../constants/constants';

export default function ForcedPasswordChange() {
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { t } = useTranslation();
  const { setIsAuthenticated, setIsSuperadmin, setIsSidebarOpen, cognitoUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { setIsSnackbarOpen, setSnackbarMessage, setSnackbarType } = useAppContext();

  function validateForm() {
    return newPassword === passwordConfirmation;
  }

  const authorizeIfAdmin = (user) => {
    const groups = getGroups(user);
    if (groups === undefined) {
      throw new Error(t('user_not_admin_error'));
    }
    if (isAdmin(groups)) {
      if (isSuperadminCheck(groups)) {
        setIsSuperadmin(true);
      }
      setIsAuthenticated(true);
      setIsSidebarOpen(true);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    // console.log(requiredAttributes);
    Auth.completeNewPassword(
      cognitoUser, // the Cognito User Object
      newPassword, // the new password
      // OPTIONAL, the required attributes
      {},
    )
      .then((user) => {
        authorizeIfAdmin(user);
      })
      .catch((e) => {
        setSnackbarType(SNACKBAR_TYPE_ERROR);
        setSnackbarMessage(getErrorMessage(e));
        setIsSnackbarOpen(true);
        setIsLoading(false);
      });
  }

  return (
    <div className="unauthenticated__form__container">
      <form onSubmit={handleSubmit}>
        <TextField
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="passwordConfirmation"
          label="Password Confirmation"
          name="passwordConfirmation"
          autoFocus
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={!validateForm() || isLoading}>
          {isLoading ? <CircularProgress size="24.5px" style={{ marginRight: 5 }} /> : null}
          {t('login')}
        </Button>
      </form>
    </div>
  );
}
