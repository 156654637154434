import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomMaterialTable from './CustomMaterialTable';
import '../styles/ReportDetails.css';
import { formatDateToStr } from '../libs/helperLib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '67%',
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    paddingTop: '20px',
  },
  date: {
    display: 'inline',
    marginLeft: '10px',
  },
  username: {
    display: 'inline',
  },
}));

const Comments = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, fullPage } = props;
  if (!data) {
    return <Typography className="reportDetails__grid__item">{t('no_comments')}</Typography>;
  }

  return (
    <Box className={classes.root} style={{ width: fullPage ? '67%' : '100%' }}>
      <CustomMaterialTable
        columns={[
          {
            title: 'Date and User',
            field: 'date',
            filtering: false,
            defaultSort: 'desc',
            cellStyle: { maxWidth: 200 },
            render: (rowData) => (
              <span>
                {formatDateToStr(new Date(rowData.date))} {rowData.user}:
              </span>
            ),
          },
          { title: 'Comment', field: 'content', filtering: false },
        ]}
        data={data}
        title="Comments"
        options={{
          search: true,
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [],
          toolbar: false,
          draggable: false,
          padding: 'dense',
          header: false,
          paging: false,
        }}
      />
    </Box>
  );
};

Comments.defaultProps = {
  data: [],
  fullPage: false,
};
Comments.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
    }),
  ),
  fullPage: PropTypes.bool,
};

export default Comments;
